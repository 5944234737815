<div class="render-limit-selector">
  <div class="render-limit-options" [class.maxRowElements5]="inputMaxElementsInRow === 5" [class.maxRowElements4]="inputMaxElementsInRow === 4"  >
    <button
      *ngFor="let limit of renderLimitOptions"
      [class.selected]="isSelected(limit)"
      [class.no-limit-option]="limit === 9999"
      [disabled]="limit !== 0 && limit <= firebaseAuth.currentUser?.renderModelsPerCycle"
      (click)="setRenderLimit(limit)"
    >
      {{ limit === 9999 ? 'Don`t Notify' : limit }}
    </button>
    <!-- <span class="current-cycle-label">Current Render Period: {{firebaseAuth.currentUser?.renderModelsPerCycle}} <span *ngIf="getCurrentUploadingCards() > 0">+ {{getCurrentUploadingCards()}} Rendering</span></span> -->
  </div>
</div>
