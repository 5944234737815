import { INavpanelAction, NAVPANEL_ACTION_TYPE, ICardExtended, SUBSCRIPTION_STATUS } from './../../models/card';
import { FirebaseAuthService } from './firebase-auth.service';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';
import { ICollaborator } from './../../models/collaboration';
import { UploadFileService } from './upload-file.service';
import { SettingsService } from './settings.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { DEFAULT_USER_COLLECTION_ID, DtoICard, DtoICollection, ICardCollection } from 'src/app/models/collection';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { Injectable } from '@angular/core';
import { CARD_PURCHASE_STATUS, ICard, ModelCardRendered, ModelCardRenderedSelection } from 'src/app/models/card';
import { CardsService } from './cards.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { IGroupedCard } from 'src/app/models/groupedCard';

const CORRECT_APP_POSTER = environment.type === 'web' ? 'cardPosterWeb' : 'cardPosterMobile';
const CORRECT_APP_CONTENT = environment.type === 'web' ? 'cardContentWeb' : 'cardContentMobile';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

    public isUserAuthenticated: boolean;
    public notLoggedSubscription: Subscription;

    // used for making transition between edit-collection/library more seamless
    public librarySavedScrollPosition: number;

    private isNavbarMainActionDisabled: boolean = false;

    public userReviewPublishRejectModels: ICardExtended[] = [];

    public userDraftCollections: ICardCollection[] = [];

    // public userNotInCollectionCards: ModelCardRendered[] = [];
    // public defaultUserCollection: ICardCollection = this.mockDataService.generateNotInCollectionGalleryBasedOnMock(this.mockDataService.galleryNotInCollection, '_1628_2500k.mp4');

    public savedCards: ICard[] = [];
    public savedCardsSubject: Subject<ICard[]> = new Subject();

    public savedCollections: ICardCollection[] = [];
    public savedCollectionsSubject: Subject<ICardCollection[]> = new Subject();

    public uploadedCards: ICard[] = [];

    public toDeleteCardBuffer: ModelCardRenderedSelection[] = [];
    public deletedCards: ModelCardRenderedSelection[] = [];
    public deletedCollections: ICardCollection[] = [];

    public dragOver: boolean;

    // public userPurchasedCollections: ICardCollection[] = [];
    public userPurchasedCards: ICard[] = [];
    public userPurchasedCollections: ICardCollection[] = [];

    public userAllModelsCollection: ICardCollection;

    public uploadedCardRecieved: Subject<ICard[]> = new Subject();

    public initilizeUserDataFinished: Subject<boolean> = new Subject();

    public userCollectionSnapshot: ICardCollection;

    public isRecievedModelsRespFromBackend: boolean;
    public isRecievedDraftCollectionsRespFromBackend: boolean;
    public recievingModelsRespBackendProgress: number = 1;

    // NEW COLLECTION
    public newCollectionName: string;
    uploadServiceSubscription: Subscription;


    private temporaryPurchaedCardsMock = [
      '0f751bb3a3024ff19aad51cfb0562166',
      '257500990e514ffaa09cf9735f758026',
      '9debbf19477f4a0ba0eff682cf2677ee',
      'cdf1218c6bc54f068ab86e8f56f658a2',
      'd50aa93fc56347fb8df2b8a206a740da',
      'e97b94d9720846428ce453ddc535787d',
      'ubiseaifa_LOD01675749555738'
    ]
  isLogoutHappened: boolean;

  public get allUserCards() {
    return [...this.uploadedCards, ...this.savedCards, ...this.userPurchasedCards, ]
  }

  public get isUserSubscribed() {
    return this.firebaseAuth.currentUser?.subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBER;
  }

  public get userSubscriptionStatus() {
    return this.firebaseAuth.currentUser?.subscriptionStatus;
  }

  public get isTryFreeEnabled() {
    return this.firebaseAuth.currentUser?.subscriptionStatus === SUBSCRIPTION_STATUS.GUEST;
  }

  public get isFreeUploadLimitReached() {
    console.log('this.firebaseAuth.currentUser?.uploadsNumber', this.firebaseAuth.currentUser?.uploadsNumber);
    console.log('this.firebaseAuth.currentUser?.uploadNumberLimit', this.firebaseAuth.currentUser?.uploadNumberLimit);
    console.log('isFreeUploadLimitReached', this.firebaseAuth.currentUser?.uploadsNumber >= this.firebaseAuth.currentUser?.uploadNumberLimit);
    return  this.firebaseAuth.currentUser?.uploadsNumber >= this.firebaseAuth.currentUser?.uploadNumberLimit
  }

  public get publishedUserCards() {
    return this.userReviewPublishRejectModels.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PUBLISHED);
  }

  public get rejectedUserCards() {
    return this.userReviewPublishRejectModels.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED);
  }

  public get inReviewUserCards() {
    return this.userReviewPublishRejectModels.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW);
  }

  public get reviewAndPublishedCards() {
    return this.userReviewPublishRejectModels.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW || card.purchaseStatus === CARD_PURCHASE_STATUS.PUBLISHED);
  }

  // public get allUserDraftCollections() {
  //   return this.userDraftCollections.forEach((col) =>{
  //     const modelIds = col.collectionCards.map(card => card.id);
  //     this.allUserCards.filter(card => modelIds.includes(card.id))
  //   })

  //   return this.userDraftCollections.filter(col => col.collectionId !== DEFAULT_USER_COLLECTION_ID);
  // }

  public get allUserDraftCollections() {
    return this.userDraftCollections.map(collection => ({
      ...collection,
      collectionCards: this.getFilteredModelsFromAllUserCards(collection.collectionCards)
    }));
  }



  // eslint-disable-next-line max-len
  constructor(public mockDataService: MockDataService, public firebaseAuth: FirebaseAuthService, public settingService: SettingsService,
     public uploadService: UploadFileService, public utilityFunctionsService: UtilityFunctionsService,
     public cardService: CardsService, private _snackBar: MatSnackBar) {

      this.notLoggedSubscription = this.firebaseAuth.notLoggedUserSubscription.subscribe(() => {
        this.isUserAuthenticated = false;
        this.isLogoutHappened = true;
      })

    this.incrementProgress();
    this.firebaseAuth.firebaseUser.subscribe((user) => {
      this.isUserAuthenticated = false;
      if(user) {
        this.isUserAuthenticated = true;
        if(this.isLogoutHappened) {
          this.cardService.collectionFeedInit();
        }
        this.updateSavedModelsAndCollectionsDoneForAnonymous();
        this.initializeUserData();
        this.initUserPurchases();
      }
    });
    this.subscribeToLatestUploadedModel();

  }

  private updateSavedModelsAndCollectionsDoneForAnonymous() {
    this.savedCards.forEach(card => {
      this.cardService.setUserLike(card)
    });
    this.savedCollections.forEach(collection => {
      this.cardService.setUserLike(collection)
    });
  }

  // REFACTORED

  private async initializeUserData() {
    this.getAllUserLikes(); // Assuming this is an async method without a need to await its result
    await this.fetchUserUploadedModels();
    await this.fetchUserDraftCollections();
    await this.fetchUserReviewPublishRejectModels();
    this.initilizeUserDataFinished.next(true);
  }

  private async fetchUserUploadedModels() {
    try {
      const uid = await this.firebaseAuth.getUserUid();
      const models = await this.cardService.getUserUploadedModels(uid);
      this.isRecievedModelsRespFromBackend = true;
      if (models.length > 0) {
        this.processUploadedModels(models);
      }
    } catch (err) {
      console.log(err);
      this.isRecievedModelsRespFromBackend = true;
    }
  }

  private processUploadedModels(models: ModelCardRendered[]) {
    this.uploadedCards = models;
    console.log('this.uploadedCards', this.uploadedCards);
    if (environment.type === 'web') {
      this.filterWebCards();
    }
    this.uploadedCardRecieved.next(this.uploadedCards);
  }

  private filterWebCards() {
    this.uploadedCards = this.uploadedCards.filter(card =>
      card.cardContentWeb && !this.uploadService.uploadModels.some(uploadModel => uploadModel.id === card.id)
    );
  }

  private async fetchUserDraftCollections() {
    try {
      const col = await this.cardService.getUserDraftCollections();
      console.log('Draft User Collection Recieved', col);
      this.isRecievedDraftCollectionsRespFromBackend = true;
      this.userDraftCollections = col;
    } catch (err) {
      this.isRecievedDraftCollectionsRespFromBackend = true;
      console.log(err);
    }
  }

  private async fetchUserReviewPublishRejectModels() {
    try {
      const resp = await this.cardService.getUserReviewPublishRejectModels();
      this.userReviewPublishRejectModels = resp;
      this.processRejectedCards();
    } catch (err) {
      console.log(err);
    }
  }

  private processRejectedCards() {
    const rejectedCards = this.userReviewPublishRejectModels.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED);
    rejectedCards.forEach((card: ICardExtended, indx) => {
      this.cardService.getUserReviewPublishRejectModelById(card.publicModelId).then((response) => {
        card = this.cardService.processExtendedCardsFromDto([response])[0];
        this.userReviewPublishRejectModels[indx].reviewDetails = card.reviewDetails;
      }).catch(err => console.log(err));
    });
  }

  subscribeToLatestUploadedModel() {
    this.uploadServiceSubscription = this.uploadService.latestUploadedModelSubscription.subscribe(async item => {
      console.log('latestUploadedModelSubscription', item);
      try {
        const newCard = await this.processUploadedModel(item);
        this.addCardToCollections(newCard, item.linkedCollectionId);
      } catch (error) {
        console.error(error);
      }
    });
  }

  async processUploadedModel(item: any) {
    const newMockCardName = this.generateMockCardName(this.uploadService.uploadModels.length);
    const newCard = this.mockDataService.generateModelCardRenderedMocksBasedOnImgUrl([newMockCardName], '_1628_2500k.mp4')[0];
    this.assignCardProperties(newCard, item);

    // Attempt to set the card model link
    try {
      // Assuming getDownloadUrlRef is an async function
      // newCard.cardModelLink = await this.firebaseAuth.getDownloadUrlRef(item.uploadStatus.uploadFileRef);
    } catch (error) {
      throw new Error('Failed to get card model link');
    }

    return newCard;
  }

  generateMockCardName(uploadFileIndex: number) {
    const fileIndexFormatted = uploadFileIndex > 9 ? `${uploadFileIndex}` : `0${uploadFileIndex}`;
    return `${this.mockDataService.genericMockCardNameBasis}${fileIndexFormatted}/${fileIndexFormatted}_poster.webp`;
  }

  assignCardProperties(newCard: ICard, item: IGroupedCard) {
    Object.assign(newCard, {
      cardTitle: item.title,
      id: item.id,
      cardAuthor: this.firebaseAuth.currentUser.uid,
      cardPrice: undefined,
      cardCategory: undefined,
      parentCollectionId: item.linkedCollectionId,
      cardPosterMobile: item.previewImageMobile,
      cardContentMobile: item.previewVideoMobile,
      cardPosterWeb: item.previewImageWeb,
      cardContentWeb: item.previewVideoWeb,
      purchaseStatus: CARD_PURCHASE_STATUS.UPLOADED
    });
  }

  addCardToCollections(newCard: any, linkedCollectionId?: string) {
    if (linkedCollectionId) {
      const collectionForUploadedCard = this.userDraftCollections.find(col => col.collectionId === linkedCollectionId);
      if (!collectionForUploadedCard.collectionCards.find(card => card.id === newCard.id)) {
        collectionForUploadedCard.collectionCards.unshift(newCard);
        this.uploadedCards.unshift(newCard);
        this.cardService.updateCollectionPostRequest(collectionForUploadedCard);
      } else {
        console.error('Card already exists in collection');
      }
    } else {
      if (!this.uploadedCards.find(card => card.id === newCard.id)) {
        this.uploadedCards.unshift(newCard);
      } else {
        console.error('Card already exists in uploaded cards');
      }
    }
  }


  private getFilteredModelsFromAllUserCards(collectionCards: ModelCardRenderedSelection[]) {
    const modelIds = collectionCards.map(card => card.id);
    const allUserCardsById = new Map(this.allUserCards.map(card => [card.id, card]));
    return modelIds.map(id => allUserCardsById.get(id)).filter(card => card != null);
  }

  public createUserCollection(basicEmptyMock: ICardCollection): void {
    this.userDraftCollections.unshift(basicEmptyMock);
  }

  public updateUserCollectionId(oldId: string, newId: string): void {
    const index = this.userDraftCollections.findIndex(col => col.collectionId === oldId);
    this.userDraftCollections[index].collectionId = newId;
  }

  public updateUserCollectionInUserDrafCollections(collection: ICardCollection): void {
    const index = this.userDraftCollections.findIndex(col => col.collectionId === collection.collectionId);
    this.userDraftCollections[index] = collection;
  }

  public removeUnlikedModelFromAllDraftCollections(modelId: string): void {
    this.userDraftCollections.forEach(col => {
      col.collectionCards = col.collectionCards.filter(card => card.id !== modelId);
    })
  }

  async getAllUserLikes() {
    // Make both requests concurrently
    const [feed, response]: [any, {colections_likes: DtoICollection[]; models_likes: DtoICard[]}] = await Promise.all([
      this.cardService.getCollectionFeed(),
      this.cardService.getAllUserLikes()
    ]);
  // Process the collections likes
  if (response.colections_likes) {
    response.colections_likes.forEach(collectionData => {
      const collectionId = collectionData.collection_id;
      const collection = this.cardService.getCollectionFromHomeFeedById(collectionId);
      if (collection) {
        // If necessary, additional properties can be processed here
        this.addToSavedCollections(collection, false);
      }
    });
  }

  // Process the models likes
  if (response.models_likes) {
    response.models_likes.forEach(modelData => {
      const modelId = modelData.model_id;
      const card = this.cardService.getCardById(modelId);
      if (card) {
        // If necessary, additional properties can be processed here
        this.addToSavedCards(card, false);
      }
    });
  }
  }

  public async helperRemoveAllLikedModels() {
    const response: {colections_likes: string[]; models_likes: string[]} = await this.cardService.getAllUserLikes()
    if(response.models_likes) {
      response.models_likes.forEach((cardId) => {
        this.cardService.removeUserLike(this.cardService.getCardById(cardId))
      });
    }
  }

  async initRealLikedModels() {
    // WORKS FOR 3 COLLECTIONS;
    const homeCollections = await this.cardService.getCollectionFeed();
    const purchasedCardsRaw: any = await this.cardService.getListOfUserPurchasedModels();
    const purchasedCardsList: string[] = purchasedCardsRaw.model_ids.map((id: any) => id);
    console.log('purchasedCardsList', purchasedCardsList)
    const likesForFirstCol = this.getUniqueRandomNumbers(homeCollections[0].collectionCards.length - 1, this.getUniqueRandomNumbers(5, 1)[0]);
    const likesForSecondCol = this.getUniqueRandomNumbers(homeCollections[1].collectionCards.length - 1, this.getUniqueRandomNumbers(5, 1)[0]);
    const likesForThirdCol = this.getUniqueRandomNumbers(homeCollections[2].collectionCards.length - 1, this.getUniqueRandomNumbers(5, 1)[0]);
    likesForFirstCol.forEach(likeIndex => {
      const isPurchased = purchasedCardsList.includes(homeCollections[0].collectionCards[likeIndex].id);
      if(!isPurchased) this.addToSavedCards(homeCollections[0].collectionCards[likeIndex])
    });
    likesForSecondCol.forEach(likeIndex => {
      const isPurchased = purchasedCardsList.includes(homeCollections[1].collectionCards[likeIndex].id);
      if(!isPurchased) this.addToSavedCards(homeCollections[1].collectionCards[likeIndex])
    });
    likesForThirdCol.forEach(likeIndex => {
      const isPurchased = purchasedCardsList.includes(homeCollections[2].collectionCards[likeIndex].id);
      if(!isPurchased) this.addToSavedCards(homeCollections[2].collectionCards[likeIndex])
    });

    const randomHomeCollectionToSave = this.getUniqueRandomNumbers(homeCollections.length - 1, 1)[0];
    homeCollections[randomHomeCollectionToSave] = this.updateCardsToPurchasedInCollection(homeCollections[randomHomeCollectionToSave], purchasedCardsList);
    this.savedCollections.push(homeCollections[randomHomeCollectionToSave]);
  }
  updateCardsToPurchasedInCollection(col: ICardCollection, purchasedListId: string[]): ICardCollection {
    col.collectionCards.forEach(card => {
      const isPurchased = purchasedListId.includes(card.id);
      if(isPurchased) card.purchaseStatus = CARD_PURCHASE_STATUS.PURCHASED;
    });
    return col
  }

  async initUserPurchases() {
    const homeCollections = await this.cardService.getCollectionFeed();
    const purchasedCardsRaw: any = await this.cardService.getListOfUserPurchasedModels();
    const purchasedCardsList: string[] = purchasedCardsRaw?.model_ids?.map((id: any) => id);

    homeCollections?.forEach(collection => {
      collection?.collectionCards?.forEach(card => {
        const isPurchased = purchasedCardsList?.includes(card.id);
        if(isPurchased) this.purchaseCards([card], collection)
      })
    })
  }

  private getUniqueRandomNumbers(max, count): number[] {
    const result = [];
    for (let i = 0; i < count; i++) {
      let randomNum = Math.floor(Math.random() * (max + 1));
      while (result.includes(randomNum)) {
        randomNum = Math.floor(Math.random() * (max + 1));
      }
      result.push(randomNum);
    }
    return result;
  }

  private filterCollectionForPurchasedCards(collectionsToFilter: ICardCollection[]): ICard[] {
    const cards = []
    collectionsToFilter.forEach(draftCol => {
      const purchasedCards = draftCol.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED)
      if(purchasedCards) cards.push(...purchasedCards)
    })
    return cards
  }

  public getIsNavbarMainActionDisabled(): boolean {
    return this.isNavbarMainActionDisabled;
  }

  public changeStateIsNavbarMainActionDisabledTo(isDisabled: boolean): void {
    this.isNavbarMainActionDisabled = isDisabled;
  }

  public isCardExitsInSaved(cardToCheck: ICard): boolean {
    const cardIndex = this.savedCards.findIndex((current: ICard)=> {
      return current.cardContentMobile === cardToCheck?.cardContentMobile && current.cardTitle === cardToCheck?.cardTitle;
    })
    return cardIndex >= 0;
  }

  public isCardExitsInSavedById(cardId: string): boolean {
    const cardIndex = this.savedCards.findIndex((current: ICard)=> {
      return current.id === cardId
    })
    return cardIndex >= 0;
  }

  public getSavedCards(): ICard[] {
    return this.savedCards;
  }

  public addToSavedCards(card: ICard, saveInDB: boolean = true): void {
    if(!this.isCardExitsInSaved(card)) {
      if(saveInDB) this.firebaseAuth.getUserUid().then(uid => this.cardService.setUserLike(card))
      this.savedCards.unshift(card);
      this.savedCardsSubject.next(this.savedCards);
    }
  }

  public removeFromSavedCards(cardToRemove: ICard, saveInDB: boolean = true): ICard {
    let cardRemoved: ICard;
    if(this.isCardExitsInSaved(cardToRemove)) {
      if(saveInDB) this.firebaseAuth.getUserUid().then(uid => this.cardService.removeUserLike(cardToRemove))
      const cardIndex = this.savedCards.findIndex((current: ICard)=> {
        return current.cardContentMobile === cardToRemove.cardContentMobile && current.cardTitle === cardToRemove?.cardTitle;
      })
      cardRemoved = this.savedCards.splice(cardIndex, 1)[0];
      this.removeUnlikedModelFromAllDraftCollections(cardRemoved.id);
      this.savedCardsSubject.next(this.savedCards);


    }
    return cardRemoved
  }

  public removeFromSavedCardsById(CardIdToRemove?: string) {
    let cardRemoved: ICard;
    if(this.isCardExitsInSavedById(CardIdToRemove)) {
      const cardIndex = this.savedCards.findIndex((current: ICard)=> {
        if(CardIdToRemove) return current.id === CardIdToRemove;
      })
      cardRemoved = this.savedCards.splice(cardIndex, 1)[0];
      this.savedCardsSubject.next(this.savedCards);
    }
    return cardRemoved
  }


  // LOGIC FOR COLLECTIONS;
  public isCollectionExitsInSaved(collectionOrId: ICardCollection | string): boolean {
    if(!collectionOrId) return

    let collectionId: string;

    // Determine if the input is an ICardCollection object or just a collectionId string
    if (typeof collectionOrId === 'string') {
      collectionId = collectionOrId;
    } else {
      collectionId = collectionOrId.collectionId;
    }

    // Find the collection by its ID
    const collectionIndex = this.savedCollections.findIndex((current: ICardCollection) => {
      return current.collectionId === collectionId;
    });

    return collectionIndex >= 0;
  }


  public getSavedCollections(): ICardCollection[] {
    return this.savedCollections;
  }

  public addToSavedCollections(collection: ICardCollection, saveInDB: boolean = true): void {
    if(saveInDB) this.firebaseAuth.getUserUid().then(uid => this.cardService.setUserLike(collection))

    if(this.cardService.getCollectionFeedCardsById(collection.collectionId)) collection.collectionCards = this.cardService.getCollectionFeedCardsById(collection.collectionId);
    console.log('addToSavedCollections', collection)
    if(!this.isCollectionExitsInSaved(collection)) {
      this.savedCollections.unshift(collection);
      // for debugging saved collections on the first screen;
      // this.savedCollections.push(collection);
      // if(this.savedCollections.length > 5) this.savedCollections.unshift(collection); this.savedCollections.unshift(collection);
      this.savedCollectionsSubject.next(this.savedCollections);
    }
  }

  public removeFromSavedCollections(collectionToRemove: ICardCollection, saveInDB: boolean = true): void {
    if(saveInDB) this.firebaseAuth.getUserUid().then(uid => this.cardService.removeUserLike(collectionToRemove));

    if(this.isCollectionExitsInSaved(collectionToRemove)) {
      const collectionIndex = this.savedCollections.findIndex((current: ICardCollection)=> {
        return current.collectionId === collectionToRemove.collectionId;
      })
      this.savedCollections.splice(collectionIndex, 1);
      this.savedCollectionsSubject.next(this.savedCollections);
    }
  }

  public addMockCollaborator(): ICollaborator {
    let collab;
    if(this.mockDataService.collaboratorsList.length > 0) {
      collab = this.mockDataService.collaboratorsList.splice(0,1);
      return collab[0];
    }
    return collab
  }

  // RESTORE DELETED CARD/COLLECTIONS;
  public restoreRecentlyDeleted(): number {
    const cardsToRestore = this.deletedCards?.filter(card => card.isSelected);
    const cardToRestoreLength = cardsToRestore.length;
    const collectionsToRestore = this.deletedCollections?.filter(col => col.isSelected);
    console.log('RESTORE CARDS NUMB: ', cardsToRestore?.length, '  RESTORE COL NUMB: ', collectionsToRestore?.length)
    if (cardsToRestore?.length) {
      for (let index = 0; index < cardToRestoreLength; index++) {
        const detachedCard = cardsToRestore.splice(0, 1)[0];
        if (detachedCard.parentCollectionId) {
          const cardParentCollection = this.userDraftCollections.find((col) => col.collectionId === detachedCard.parentCollectionId)
          if (cardParentCollection) cardParentCollection.collectionCards.push(detachedCard);
          else this.addCardsToDefaultUserCollection([detachedCard])
        } else this.addCardsToDefaultUserCollection([detachedCard])
      }

      this.deletedCards = this.deletedCards.filter(card => !card.isSelected);
      return cardToRestoreLength;
    }

    // NOT USED NOW;
    if(collectionsToRestore?.length) {
      const colToRestoreLength = collectionsToRestore.length;
      for (let index = 0; index < colToRestoreLength; index++) {
        const detachedCol = collectionsToRestore.splice(0,1)[0];
        this.userDraftCollections.push(detachedCol);
      }
      this.deletedCollections = this.deletedCollections.filter(col => !col.isSelected);
    }
  }


  public tryForFreeClicked(): void {
    this.cardService.activateTrialPostRequest().then((resp) => {
      this.firebaseAuth.currentUser.subscriptionStatus = SUBSCRIPTION_STATUS.TRIAL;
      // this.utilityFunctionsService.showImportantSnackBar('Free Trial Activated', 350);
    });
    // this.currentUser.isTryForFreeClicked = true;
  }

  // HELPER FUNCTIONS:
  public deleteCollectionFromDrafts(collection: ICardCollection): ICardCollection[] {
    if(collection.collectionId === DEFAULT_USER_COLLECTION_ID) {
      this.utilityFunctionsService.showErrorSnackBar('It is restricted to delete default collection',0);
      return
    }
    const delIndex = this.userDraftCollections.findIndex(col => col.collectionId === collection.collectionId);
    const deletedCollection = this.userDraftCollections.splice(delIndex, 1)[0];
    this.deletedCollections.push(deletedCollection);
    // this.userNotInCollectionCards.push(...deletedCollection.collectionCards);
    // this.addCardsToDefaultUserCollection(deletedCollection.collectionCards)
    return this.userDraftCollections;
  }

  // public deleteCardUnassignedToCollection(sourceCard: ICard): ICard[] {
  //   const targetCardIndex: number = this.userNotInCollectionCards.findIndex(thumbCard => thumbCard.cardTitle === sourceCard.cardTitle && thumbCard.cardContentMobile === sourceCard.cardContentMobile );
  //   const deletedCard = this.userNotInCollectionCards.splice(targetCardIndex, 1)[0];
  //   this.deletedCards.push(deletedCard);
  //   return this.userNotInCollectionCards
  // }

  public deleteCardInReview(sourceCard: ICard): ICard[] {
    const targetCardIndex: number = this.userReviewPublishRejectModels.findIndex(thumbCard => thumbCard.cardTitle === sourceCard.cardTitle && thumbCard.cardContentMobile === sourceCard.cardContentMobile );
    const deletedCard = this.userReviewPublishRejectModels.splice(targetCardIndex, 1)[0];
    this.deletedCards.push(deletedCard);
    return this.userReviewPublishRejectModels
  }

  public deleteCardFromPortfolio(sourceCard: ICard): ICard[] {
    switch (true) {
      case sourceCard.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED || sourceCard.purchaseStatus === CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT:
        const targetCardIndex: number = this.uploadedCards.findIndex(thumbCard => thumbCard.cardTitle === sourceCard.cardTitle && thumbCard.cardContentMobile === sourceCard.cardContentMobile );
        const deletedCard = this.uploadedCards.splice(targetCardIndex, 1)[0];
        this.deletedCards.push(deletedCard);

        // Updating collections after deletion card;
        this.userDraftCollections.forEach(col => {
            col.collectionCards = col.collectionCards.filter(card => card.id !== sourceCard.id);
        });

        return this.uploadedCards
      default:
        this.utilityFunctionsService.showErrorSnackBar('Error: no actions defined for this type of card in portfolio', 0);
        console.error('Error: no actions defined for this type of card in portfolio');
        break;
    }
  }

  public addCardToDeleteBuffer(sourceCard: ICard): void {
    this.toDeleteCardBuffer.push(sourceCard);
  }

  public cleanCardToDeleteBuffer(): void {
    this.toDeleteCardBuffer = [];
  }

  public deleteCardsFromBuffer(): void {
    this.toDeleteCardBuffer.forEach(card => {
      this.cardService.deleteModelPostRequest(card).then((resp) => {
      }).catch(error => this.utilityFunctionsService.showErrorSnackBar(`Error occured on delete ${card.cardTitle}: ${JSON.stringify(error.error)}`, 500));
    })
  }

  public async deleteCardsFromBufferAsync(): Promise<void> {
    if(this.toDeleteCardBuffer.length === 0) return;

    try {
      const deletePromises = this.toDeleteCardBuffer.map(card => {
        return this.cardService.deleteModelPostRequest(card);
      });

      await Promise.all(deletePromises);

      this.utilityFunctionsService.showSnackBar('All cards deleted successfully.', 500);
    } catch (error) {
      this.utilityFunctionsService.showErrorSnackBar(`Error occurred on delete: ${JSON.stringify(error.error)}`, 500);
    }
  }

  public createCollectionSnapshot(sourceSnapshotCollection: ICardCollection): ICardCollection {
    this.userCollectionSnapshot = JSON.parse(JSON.stringify(sourceSnapshotCollection))
    console.log('CREATED SNAP');
    return this.userCollectionSnapshot;
  }

  public restoreCollectionFromSnapshot(collectionToRestore: ICardCollection): ICardCollection {
    const targetIndx = this.userDraftCollections.findIndex(col => col.collectionId === collectionToRestore.collectionId);
    // this.userDraftCollections[targetIndx] = Object.assign(this.userCollectionSnapshot, collectionToRestore);
    this.userDraftCollections[targetIndx] = Object.assign({}, this.userCollectionSnapshot);

    console.log('SNAP RESTORED');
    return this.userDraftCollections[targetIndx];
  }

  public deleteCardFromCollection(sourceCard: ICard, sourceCollection: ICardCollection): ICardCollection {


    const targetIndx = this.userDraftCollections.findIndex(col => col.collectionId === sourceCollection.collectionId);
    const targetCardIndex: number = this.userDraftCollections[targetIndx].collectionCards.findIndex(card => card.cardTitle === sourceCard.cardTitle && card.cardContentMobile === sourceCard.cardContentMobile );

    if(targetCardIndex < 0) {
      console.error('Card not found in collection while deletion, ', sourceCard.cardTitle, sourceCard.cardContentMobile, sourceCollection.collectionId);
      return
    }

    const deletedCard = this.userDraftCollections[targetIndx].collectionCards.splice(targetCardIndex,1)[0];

    // REFACTOR/RETHINK AFTER: BECAUSE IT NOT APPLICABLE NOW FOR DELETE CARD FROM COLLECTION;
    deletedCard.parentCollectionId = sourceCollection.collectionId;
    this.deletedCards.push(deletedCard);
    return this.userDraftCollections[targetIndx]
  }

  public removeCollaboratorFromCollection(sourceCollection: ICardCollection, collaborator: ICollaborator): void {
    const indxToRemove = sourceCollection.collaborators.findIndex((col)=> col.id === collaborator.id);
    if(indxToRemove >= 0) sourceCollection.collaborators.splice(indxToRemove, 1);
  }

  public organizeAllCardsToDraftCollection(sourceCards: ICard[], targetCollection: ICardCollection): ICardCollection {
    console.log('SELECTED SOURCE CARD TO ORGANIZE ', sourceCards)
    const targetColIndex: number = this.userDraftCollections.findIndex(col => col.collectionId === targetCollection.collectionId);

    const selectedSavedCards = sourceCards.filter((card) => {return this.savedCards.find((findcard) => {return findcard.cardContentMobile === card.cardContentMobile})});
    const selectedUserPurchasedCards = sourceCards.filter((card) => {return this.userPurchasedCards.find((findcard) => {return findcard.cardContentMobile === card.cardContentMobile})});
    const selectedUploadedCards = sourceCards.filter((card) => {return this.uploadedCards.find((findcard) => {return findcard.cardContentMobile === card.cardContentMobile})});

    console.log('SAVED', selectedSavedCards);
    console.log('PURCHASED', selectedUserPurchasedCards);
    console.log('UPLOADED', selectedUploadedCards);

    if(selectedSavedCards) {
      selectedSavedCards.forEach(sCard => {
        const cardToMove = this.savedCards.find(card => card.cardTitle === sCard.cardTitle && card.cardContentMobile === sCard.cardContentMobile);
        if (cardToMove) {
          const cardCopy = { ...cardToMove, purchaseStatus: CARD_PURCHASE_STATUS.DRAFT_LIKED };
          this.userDraftCollections[targetColIndex].collectionCards.unshift(cardCopy);
        }
      });
    }

    if(selectedUserPurchasedCards) {
      selectedUserPurchasedCards.forEach(sCard => {
        const cardToMove = this.userPurchasedCards.find(card => card.cardTitle === sCard.cardTitle && card.cardContentMobile === sCard.cardContentMobile);
        this.userDraftCollections[targetColIndex].collectionCards.unshift(cardToMove);
      })
    }

    if(selectedUploadedCards) {
      selectedUploadedCards.forEach(sCard => {
        const cardToMove = this.uploadedCards.find(card => card.cardTitle === sCard.cardTitle && card.cardContentMobile === sCard.cardContentMobile);
        this.userDraftCollections[targetColIndex].collectionCards.unshift(cardToMove);
      })
    }

    return this.userDraftCollections[targetColIndex];
  }

  public mutableOrganizeAllCardsToDraftCollectionOld(sourceCards: ICard[], targetCollection: ICardCollection): void {
    const targetColIndex: number = this.userDraftCollections.findIndex(col => col.collectionId === targetCollection.collectionId);

    const selectedSavedCards = sourceCards.filter((card) => {return this.savedCards.find((findcard) => {return findcard.cardContentMobile === card.cardContentMobile})});
    const selectedUserPurchasedCards = sourceCards.filter((card) => {return this.userPurchasedCards.find((findcard) => {return findcard.cardContentMobile === card.cardContentMobile})});
    const selectedUploadedCards = sourceCards.filter((card) => {return this.uploadedCards.find((findcard) => {return findcard.cardContentMobile === card.cardContentMobile})});

    if(selectedSavedCards) {
      selectedSavedCards.forEach(sCard => {
        const sourceCardIndex: number = this.savedCards.findIndex(card => card.cardTitle === sCard.cardTitle && card.cardContentMobile === sCard.cardContentMobile );
        const cardToMoveDetached = this.savedCards.splice(sourceCardIndex,1);
        cardToMoveDetached[0].purchaseStatus = CARD_PURCHASE_STATUS.DRAFT_LIKED;
        this.userDraftCollections[targetColIndex].collectionCards.unshift(cardToMoveDetached[0]);
      })
    }

    if(selectedUserPurchasedCards) {
      selectedUserPurchasedCards.forEach(sCard => {
        const sourceCardIndex: number = this.userPurchasedCards.findIndex(card => card.cardTitle === sCard.cardTitle && card.cardContentMobile === sCard.cardContentMobile );
        const cardToMoveDetached = this.userPurchasedCards.splice(sourceCardIndex,1);
        this.userDraftCollections[targetColIndex].collectionCards.unshift(cardToMoveDetached[0]);
      })
    }

    if(selectedUploadedCards) {
      selectedUploadedCards.forEach(sCard => {
        const sourceCardIndex: number = this.uploadedCards.findIndex(card => card.cardTitle === sCard.cardTitle && card.cardContentMobile === sCard.cardContentMobile );
        const cardToMoveDetached = this.uploadedCards.splice(sourceCardIndex,1);
        this.userDraftCollections[targetColIndex].collectionCards.unshift(cardToMoveDetached[0]);
      })
    }
  }

  public moveCardFromPurchasedToSelectedDraftCollection(sourceCards: ICard[], targetCollection: ICardCollection) {

    sourceCards.forEach(sourceCard => {
      const sourceCardIndex: number = this.userPurchasedCards.findIndex(card => card.cardTitle === sourceCard.cardTitle && card.cardContentMobile === sourceCard.cardContentMobile );
      const targetColIndex: number = this.userDraftCollections.findIndex(col => col.collectionId === targetCollection.collectionId);
      const cardToMoveDetached = this.userPurchasedCards.splice(sourceCardIndex,1);

      const purchasedCollectionIndex = this.userPurchasedCollections.findIndex(purCol => purCol.collectionId === sourceCard.parentCollectionId);
      const purchasedCollectionCardIndex = this.userPurchasedCollections[purchasedCollectionIndex].collectionCards.findIndex(card => card.cardTitle === sourceCard.cardTitle && card.cardContentMobile === sourceCard.cardContentMobile );
      this.userPurchasedCollections[purchasedCollectionIndex].collectionCards.splice(purchasedCollectionCardIndex, 1);
      // eslint-disable-next-line max-len
      if(this.userPurchasedCollections[purchasedCollectionIndex].collectionCards.filter((card)=>{return card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED}).length === 0) this.userPurchasedCollections.splice(purchasedCollectionIndex, 1);

      this.userDraftCollections[targetColIndex].collectionCards.unshift(cardToMoveDetached[0]);
    });
  }

  // public moveUnassignedCardToSelectedDraftCollection(sourceCard: ICard, targetCollection: ICardCollection) {
  //   console.log('MOVE')
  //   // const sourceCardIndex: number = this.defaultUserCollection.collectionCards.findIndex(card => card.cardTitle === sourceCard.cardTitle && card.cardContentMobile === sourceCard.cardContentMobile );
  //   // const targetColIndex: number = this.userDraftCollections.findIndex(col => col.collectionId === targetCollection.collectionId);
  //   // const cardToMoveDetached = this.defaultUserCollection.collectionCards.splice(sourceCardIndex,1);
  //   // this.userDraftCollections[targetColIndex].collectionCards.unshift(cardToMoveDetached[0]);
  // }

  public moveSelectedCardBetweenCollectionDrafts(sourceCard: ICard, sourceCollection: ICardCollection, targetCollection: ICardCollection): ICardCollection {
    const sourceColIndex: number = this.userDraftCollections.findIndex(col => col.collectionId === sourceCollection.collectionId);
    const sourceCardIndex: number = this.userDraftCollections[sourceColIndex].collectionCards.findIndex(card => card.cardTitle === sourceCard.cardTitle && card.cardContentMobile === sourceCard.cardContentMobile );
    const targetColIndex: number = this.userDraftCollections.findIndex(col => col.collectionId === targetCollection.collectionId);

    const cardToMoveDetached = this.userDraftCollections[sourceColIndex].collectionCards.splice(sourceCardIndex,1);
    this.userDraftCollections[targetColIndex].collectionCards.unshift(cardToMoveDetached[0]);

    return this.userDraftCollections[sourceColIndex];

  }

  // NOT IN COLLECTION FUNCs

  // public updateNotInCollectionCard(updateCard: ModelCardRendered): void {
  //   if(updateCard === undefined) return;

  //   const cardToUpdateIndex = this.defaultUserCollection.collectionCards.findIndex(card  => {
  //     return card.id === updateCard.id;
  //   })
  //   // if(cardToUpdateIndex >= 0) {
  //   //   this.defaultUserCollection.collectionCards[cardToUpdateIndex] = updateCard;
  //   // } else {
  //   //   this.defaultUserCollection.collectionCards.push(updateCard);
  //   // }

  //   this.defaultUserCollection.collectionCards[0] = updateCard;
  // }

  public addCardsToDefaultUserCollection(sourceCards: ICard[]): void {

    const selectedUserPurchasedCards = sourceCards.filter((card) => {return card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED});
    const selectedUploadedCards = sourceCards.filter((card) => {return card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED});

    this.userPurchasedCards.push(...this.userPurchasedCards, ...selectedUserPurchasedCards);
    this.uploadedCards.push(...this.userPurchasedCards, ...selectedUploadedCards);

  }

  // public getDefaultCollectionCards(): ModelCardRenderedSelection[] {
  //   return this.defaultUserCollection.collectionCards
  // }

  // USE CardID instead of poster data!!!!
  public isCardPurchasedCheckById(idToCheck: string): boolean {
    const indx = this.userPurchasedCards.findIndex(card => card.id === idToCheck)
    return indx > -1 ? true : false;
  }


  public purchaseSingleCard(cardTopPurchase: ICard ) {
    cardTopPurchase.purchaseStatus = CARD_PURCHASE_STATUS.PURCHASED;
    this.userPurchasedCards.push(cardTopPurchase);
    if(cardTopPurchase.parentCollectionId) {
      const parentCollectionIndex = this.userPurchasedCollections.findIndex(col => col.collectionId === cardTopPurchase.parentCollectionId);
      if(parentCollectionIndex >= 0) {
        const purchasedCardIndex = this.userPurchasedCards.findIndex(card => card.id === cardTopPurchase.id );
        const clonedPurchasedCard = JSON.parse(JSON.stringify(this.userPurchasedCards[purchasedCardIndex]));
        this.userPurchasedCollections[parentCollectionIndex].collectionCards.unshift(clonedPurchasedCard);
      }
      if(parentCollectionIndex === -1) {
        const baseCollection = this.cardService.feedCollectionCardsSource.find(col => col.collectionId === cardTopPurchase.parentCollectionId);
        if(baseCollection) {
          const clonedBasedCollection: ICardCollection = JSON.parse(JSON.stringify(baseCollection));
          clonedBasedCollection.purchaseDetails = {allCardsQuantity: undefined}
          clonedBasedCollection.purchaseDetails.allCardsQuantity = clonedBasedCollection.collectionCards.length;
          clonedBasedCollection.collectionCards = clonedBasedCollection.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED);
          const purchasedCardIndex = this.userPurchasedCards.findIndex(card => card.id === cardTopPurchase.id );
          const clonedPurchasedCard = JSON.parse(JSON.stringify(this.userPurchasedCards[purchasedCardIndex]));
          clonedBasedCollection.collectionCards.unshift(clonedPurchasedCard);
          const idColIndex = this.userPurchasedCollections.findIndex(purCol => purCol.collectionId === clonedBasedCollection.collectionId);
          if(idColIndex === -1) this.userPurchasedCollections.push(clonedBasedCollection);
          if(idColIndex >= 0) {
            this.userPurchasedCollections[idColIndex] = clonedBasedCollection
          }
        } else console.error('Parent collection not found');

      }
    } else {
      console.error('Card has no parent collection');
    }
  }

  public purchaseCards(cardsToPurchase: ModelCardRenderedSelection[], baseCollection: ICardCollection) {
      baseCollection.collectionCards.forEach((card) => {
        if(cardsToPurchase.filter(cardToPurc => cardToPurc.cardPosterMobile === card.cardPosterMobile).length > 0) {
          card.purchaseStatus = CARD_PURCHASE_STATUS.PURCHASED;
          this.userPurchasedCards.push(card);
        }
      })

      const clonedBasedCollection: ICardCollection = JSON.parse(JSON.stringify(baseCollection));
      clonedBasedCollection.purchaseDetails = {allCardsQuantity: undefined}
      clonedBasedCollection.purchaseDetails.allCardsQuantity = clonedBasedCollection.collectionCards.length;
      clonedBasedCollection.collectionCards = clonedBasedCollection.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED);
      const idColIndex = this.userPurchasedCollections.findIndex(purCol => purCol.collectionId === clonedBasedCollection.collectionId);
      if(idColIndex === -1) this.userPurchasedCollections.push(clonedBasedCollection);
      if(idColIndex >= 0) {
        this.userPurchasedCollections[idColIndex] = clonedBasedCollection
      }
  }

  public async sendModelForReview(model: ICard): Promise<void> {

    model.purchaseStatus = CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT;
    this.uploadedCards.find((card) => card.id === model.id).purchaseStatus = CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT;

    // {"review_id":"833f2c9f-aef4-4655-ba99-81772a693056","model_id":"vfvjccaqx_LOD01683650043720","review_model_id":"7673daea-3896-4164-b0fb-15b9411ac404"}
    let response
    try {
      response = await this.cardService.sendToReviewModelPostRequest(model);
    } catch (error) {
      this.utilityFunctionsService.showErrorSnackBar(`Error occurred on publishing: ${error}`, 500);
      model.purchaseStatus = CARD_PURCHASE_STATUS.UPLOADED;
      this.uploadedCards.find((card) => card.id === model.id).purchaseStatus = CARD_PURCHASE_STATUS.UPLOADED;
      return;
    }

    // Remove cards from uploaded if they are in review now:
    // const uplaodIndex = this.uploadedCards.findIndex(carduploaded => carduploaded.cardContentMobile === model.cardContentMobile);
    // if(uplaodIndex >= 0) this.uploadedCards.splice(uplaodIndex, 1);

    model.purchaseStatus = CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT;
    model.publicModelId = response.review_model_id;
    this.uploadedCards.find((card) => card.id === model.id).purchaseStatus = CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT;

    const modelsToPublish: ICard = JSON.parse(JSON.stringify(model));
    modelsToPublish.purchaseStatus = CARD_PURCHASE_STATUS.IN_REVIEW;
    this.userReviewPublishRejectModels.unshift(modelsToPublish);
  }

  public async sendModelsForReview(models: ICard[]): Promise<void> {
    for (const model of models) {
      let response
      model.purchaseStatus = CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT;
      this.uploadedCards.find((card) => card.id === model.id).purchaseStatus = CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT;
      try {
        response = await this.cardService.sendToReviewModelPostRequest(model);
      } catch (error) {
        model.purchaseStatus = CARD_PURCHASE_STATUS.UPLOADED;
        this.uploadedCards.find((card) => card.id === model.id).purchaseStatus = CARD_PURCHASE_STATUS.UPLOADED;
        this.utilityFunctionsService.showErrorSnackBar(`Error occurred on publishing: ${error}`, 500);
        return;
      }
      model.purchaseStatus = CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT;
      model.publicModelId = response.review_model_id;
      const modelsToPublish: ICard = JSON.parse(JSON.stringify(model));
      modelsToPublish.purchaseStatus = CARD_PURCHASE_STATUS.IN_REVIEW;
      this.userReviewPublishRejectModels.unshift(modelsToPublish);
    }
  }



  public cancelModelPublishing(cardToCancel: ICard): void {
    const indxToDel = this.userReviewPublishRejectModels.findIndex(card => card.id === cardToCancel.id);
    this.userReviewPublishRejectModels.splice(indxToDel,1);

    const foundCard = this.uploadedCards.find(card => card.id === cardToCancel.id);
    if (foundCard) {
      foundCard.purchaseStatus = CARD_PURCHASE_STATUS.UPLOADED;
    } else {
      console.error('Card not found in uploaded models; Probably, it was deleteted from uploaded models');
    }

    // COMMENTED DUE TO CHANGED LOGIC;
    // const colInd = this.userDraftCollections.findIndex(col => col.collectionId === cardToCancel.parentCollectionId);
    // if(colInd > - 1) {
    //   this.userDraftCollections[colInd].collectionCards.find(cardToFind => cardToFind.cardContentMobile === cardToCancel.cardContentMobile).purchaseStatus = CARD_PURCHASE_STATUS.UPLOADED;
    //   this.utilityFunctionsService.showSnackBar('Publish canceled', 0);
    // }
  }

  public getAvailableFiltersForCollection(col: ICardCollection): INavpanelAction[] {
      const filterNavPanelItems = [];
      const isAnyCardLiked = col.collectionCards.filter(card => this.isCardExitsInSaved(card) || card.purchaseStatus === CARD_PURCHASE_STATUS.DRAFT_LIKED).length > 0;
      const isAnyCardUploaded = col.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.UPLOADED).length > 0;
      const isAnyCardPurchased = col.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.PURCHASED).length > 0;
      const isAnyCardReviewing = col.collectionCards.filter(card => card.purchaseStatus === CARD_PURCHASE_STATUS.IN_REVIEW || card.purchaseStatus === CARD_PURCHASE_STATUS.PUBLISHED ||
        card.purchaseStatus === CARD_PURCHASE_STATUS.REJECTED).length > 0;

      filterNavPanelItems.push({actionName: 'All Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS })

      if(isAnyCardLiked) {
        filterNavPanelItems.push({actionName: 'Saved Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS },)
      }
      if(isAnyCardUploaded) {
        filterNavPanelItems.push({actionName: 'By You Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS },)
      }
      if(isAnyCardPurchased) {
        filterNavPanelItems.push({actionName: 'Purchased Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS },)
      }
      if(isAnyCardReviewing) {
        filterNavPanelItems.push({actionName: 'Publishing Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_REVIEW_MODELS },)
      }
      return filterNavPanelItems
  }

  public generateUploadCard(customId: string): ICard {
    const generatedCard = this.mockDataService.generateRandomCard();
    generatedCard.id = customId;
    this.uploadedCards.push(generatedCard);
    return generatedCard
  }

  public generateCollectionFromCard(card: ModelCardRendered): ICardCollection {
    return {
      collectionId: card.parentCollectionId ? card.parentCollectionId : card.id,
      collectionTitle: card.cardTitle,
      collectionDescription: card.cardDescription,
      collectionPosterWeb: card.cardPosterWeb,
      collectionContentMobile: card[CORRECT_APP_CONTENT],
      collectionAuthor: card.cardAuthor,
      collectionPosterMobile: card[CORRECT_APP_POSTER],
      collectionCards: [],
    }
  }

  public getNewCollectionDefaultName(): string {
    return `Collection ${this.userDraftCollections.length + 1}`;
  }

  public getNewUploadFileDefaultName(): string {
    const commonLength = this.uploadedCards.length + this.uploadService.uploadModels.length + 1;
    return `Model ${commonLength + 1}`;
  }

  public getMockPurchasedCards(): string[] {
    return this.temporaryPurchaedCardsMock;
  }

  public addToMockPurchasedCards(id: string): void {
    this.temporaryPurchaedCardsMock.push(id);
  }

  public getProfileNavPanelItems(): INavpanelAction[] {
    let navpanelItems
    try {
      const subscribeItem: INavpanelAction = { actionName: 'Susbcribe 3DWay Basic Plan', actionType: NAVPANEL_ACTION_TYPE.TEST_BUY_SUBSCRIPTION };
      if(this.isUserSubscribed) subscribeItem.actionName = '⭐️ Subscribed for 3DWay Basic Plan';
      if (environment.production) {
        navpanelItems = [
          { actionName: 'Sign Out', actionType: NAVPANEL_ACTION_TYPE.SIGN_OUT }
        ]
      } else {
        navpanelItems = [
          { actionName: 'Beta Settings', actionType: NAVPANEL_ACTION_TYPE.SETTINGS },
          { actionName: 'Sign Out', actionType: NAVPANEL_ACTION_TYPE.SIGN_OUT },
        ]
        if(this.settingService.additionalSettingsEnabled) {
           navpanelItems.splice(1, 0, subscribeItem);
        }
      }
      return navpanelItems

    } catch (error) {
      this.utilityFunctionsService.rollbarError(error);
      navpanelItems = [
        { actionName: 'Sign Out', actionType: NAVPANEL_ACTION_TYPE.SIGN_OUT }
      ]
      return navpanelItems
    }

  }


  public getTopNavPanelMenuItems(): INavpanelAction[] {
    let navpanelItems
    const subscribeItem: INavpanelAction = { actionName: 'Susbcribe 3DWay Basic Plan', actionType: NAVPANEL_ACTION_TYPE.TEST_BUY_SUBSCRIPTION };

    if(this.isUserSubscribed) subscribeItem.actionName = '⭐️ Subscribed for 3DWay Basic Plan';

    if (environment.production) {
      navpanelItems = [
        // { actionName: 'Purchases', actionType: NAVPANEL_ACTION_TYPE.PURCHASES },
        // { actionName: 'Test buy consumable', actionType: NAVPANEL_ACTION_TYPE.TEST_BUY_CONSUMABLE },
        { actionName: 'Contact 3DWay', actionType: NAVPANEL_ACTION_TYPE.CONTACT_US },
        // { actionName: 'Link Payments', actionType: NAVPANEL_ACTION_TYPE.LINK_PAYMENTS },
        // { actionName: 'Recently Deleted', actionType: NAVPANEL_ACTION_TYPE.RECENTLY_DELETED },
        { actionName: 'View Account', actionType: NAVPANEL_ACTION_TYPE.VIEW_ACCOUNT },
      ]
    } else {
      navpanelItems = [
        // { actionName: 'Purchases', actionType: NAVPANEL_ACTION_TYPE.PURCHASES },

        { actionName: 'Beta Settings', actionType: NAVPANEL_ACTION_TYPE.SETTINGS },
        // { actionName: 'Test buy consumable', actionType: NAVPANEL_ACTION_TYPE.TEST_BUY_CONSUMABLE },

        // { actionName: 'Show Subscribe Splash Screen', actionType: NAVPANEL_ACTION_TYPE.SHOW_SUBSCRIBE },
        { actionName: 'Contact 3DWay', actionType: NAVPANEL_ACTION_TYPE.CONTACT_US },
        { actionName: 'Link Payments', actionType: NAVPANEL_ACTION_TYPE.LINK_PAYMENTS },
        // { actionName: 'Recently Deleted', actionType: NAVPANEL_ACTION_TYPE.RECENTLY_DELETED },
        { actionName: 'View Account', actionType: NAVPANEL_ACTION_TYPE.VIEW_ACCOUNT },
      ]
      if(this.settingService.additionalSettingsEnabled) {
         navpanelItems.splice(1, 0, subscribeItem);
      }
    }
    return navpanelItems
  }

  private incrementProgress() {
    const start = this.recievingModelsRespBackendProgress;
    const end = 95;
    const duration = 1500; // 1.5 seconds
    const steps = 60; // Number of increments
    const increment = (end - start) / steps;
    const intervalTime = duration / steps;

    let currentStep = 0;

    const interval = setInterval(() => {
      if (currentStep < steps) {
        this.recievingModelsRespBackendProgress += increment;
        currentStep++;
      } else {
        this.recievingModelsRespBackendProgress = end; // Ensure it ends exactly at 95
        clearInterval(interval);
      }
    }, intervalTime);
  }

  public cleanAllUserData(): void {
    this.userDraftCollections = [];
    this.userPurchasedCollections = [];
    this.userPurchasedCards = [];
    this.uploadedCards = [];
    this.userReviewPublishRejectModels = [];
    this.savedCards = [];
    this.savedCollections = [];
    // Clean upload service data;
    this.uploadService.firebaseUploadingItem = [];
    this.uploadService.uploadModels = [];
  }

}
