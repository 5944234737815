

export enum LOCALSTORAGE_VAR {
  CUSTOM_UID = 'customUid',
  ORIGINAL_UID = 'originalUid',
  LOG_LEVEL = 'logLevel',
  FRAME_NUMBER = 'frameNumber',
  ADDITIONAL_SETTINGS_ENABLED = 'additionalSettingsEnabled',
  SUBSCRIPTION_STATUS_CACHE = 'subscriptionStatusCache',
  RENDER_LIMIT_CACHE = 'renderLimitCache',
  RENDER_LIMIT_LAST_CYLCLE_VALUE_CACHE = 'renderLimitLastCycleValueCache',
  FIRST_TIME_SCROLLED_ANY_FEED = 'firstTimeScrolledAnyFeed',
}
