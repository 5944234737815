import { SafeResourceUrl } from '@angular/platform-browser';
import { FeedbackData } from '../shared/components/feedback-card/feedback-card.component';

export enum CARD_TYPE {
  COLLECTION = 'collection',
  MODEL = 'model',
}

export enum PRELOAD_DIRECTION {
  FORWARD = 'forward',
  BACKWARD = 'backward'
}

export enum SLIDER_CARD_TYPE {
  STANDART = 'standart',
  COMPOSITE = 'composite',
}

export enum CARD_PURCHASE_STATUS {
  PURCHASED = 'purchased',
  DEFAULT_3DWAY = 'default_3dway',
  DRAFT_LIKED = 'draft_liked',
  DRAFT_NOT_LIKED = 'draft_not_liked',
  UPLOADED = 'uploaded',
  REVIEW_PUBLISH_REJECT = 'review_publish_reject',
  IN_REVIEW = 'in_review',
  REJECTED = 'rejected',
  PUBLISHED = 'published'
}

export interface IThumbCard {
  title?: string;
  imgAmount?: number;
  isCollaborative?: boolean;
  type: SLIDER_CARD_TYPE;
  images: string[];
  isSelected?: boolean;
}

export interface ILoadProgress {
  progressMark: number;
  time: number;
  eventName?: string;
}


export interface IImgPreload {
  url: string;
  readyToPreload: boolean;
}

export interface IImgPreloadSanitized extends IImgPreload {
  urlSanitized: SafeResourceUrl;
}

export interface ICardLoadProgress {
  cardName: string;
  progress: ILoadProgress[];
}

export interface ICardLoadProgressProcessed {
  cardNameShort: string;
  timeToStartPlaying: number;
  timeToFullLoad: number;
  percentOfVideoToStartPlaying: number;
}

export interface ICard {
  id: string;
  publicModelId?: string;
  cardContentMobile: string;
  cardPosterMobile: string;
  cardContentWeb: string;
  cardPosterWeb: string;
  cardModelLink?: string;
  cardType: CARD_TYPE;
  cardCategory: string;
  cardAuthor: string;
  cardTitle: string;
  cardDescription: string;
  cardPrice: number;
  parentCollectionId?: string;
  purchaseStatus: CARD_PURCHASE_STATUS;
  isSelected?: boolean;
  modifiedDate: string;
}

export interface ICardExtended extends ICard {
  ownerUid?: string;
  dateModified?: string;
  rejectReason?: string;
  reviewDetails?: IReviewDetails[];

}

export interface IReviewDetails {
  rule_id: string;
  rule_name: string;
  rule_details: string;
  rule_review_status?: 'approved' | 'rejected' | 'default';
}

export interface ICardMockSource {
  cardImg: string;
  cardVideo: string;
}

export interface ICollectionDetailsConfig {
  mode: 'default' | 'edit' | 'active-edit';
  accessType: 'Private' | 'Collaborative';
  isDescriptionEditing?: boolean;
  showFullscreenBackgroundPreview: boolean;
  cardPreload: {
    enablePreload: boolean;
    numberOfCardsToPreload: number;
  };
}
export interface ModelCardDetails extends ICard {
  preview: {
    activeImage: string;
    previewImages: string[];
  };
  detailsRatingValue: number;
  detailsNumberOfVotes: string;
  detailsPrice: string;
  detailsFullDescription: string;
  feedbackBlock: FeedbackData[];
  informationBlock: {
    name: string;
    value: string;
  }[];
  locationData?: {
    locationCode: string;
  };
}


export enum SUBSCRIPTION_STATUS {
  NOT_SET = 'not-set',
  SUBSCRIBER = 'subscriber',
  TRIAL = 'trial',
  GUEST = 'guest',
  TRIAL_EXPIRED = 'trial_expired',
  EXPIRED = 'expired'
}

export interface StripeSubscriptionData {
      description: string;
      name: string;
      stripe_product_id: string;
      is_actual: boolean;
      prices: StripePrices[];
}

export interface StripePrices {
    price_id: string;
    price_type: string;
}

export interface UserSubscriptionStatusDto {
  user_id?: string;
  user_status: SUBSCRIPTION_STATUS;
  user_status_upd?: string; // Only in firebase
  uploads_count: number;
  uploads_max_count: number;
  billing_period_start?: string; // ISO 8601 format
  billing_period_end?: string; // ISO 8601 format
  render_limit?: number;
  render_models_per_cycle?: number;
  autorenew_is_disabled?: boolean;
  autorenew_status_upd?: string; // ISO 8601 format
  billing_cycle_upd?: string; // ISO 8601 format
}




export interface JsonDtoICard {
  author: string;
  description: string;
  cardCategory?: string;
  id: string;
  previewLink: string;
  title: string;
  type: CARD_TYPE;
  posterLink?: string;
  price?: number;
}

export interface UploadModelCard extends ModelCardRendered {
  loadProgressMobile: string;
  loadStatusMobile: string;
  loadProgressWeb: string;
  loadStatusWeb: string;
  warningMobile: string;
  warningWeb: string;
}

export interface ModelCardRendered extends ICard {
  visible?: boolean;
  isFullscreen?: boolean;
  cardShortView?: boolean;
  isVideoShown?: boolean;
  isPosterShown?: boolean;
  canBePlayed?: boolean;
}

export interface ModelCardRenderedSelection extends ModelCardRendered {
  isDisabled?: boolean;
}

export interface ModelCardService extends ICard {
  showInMock?: boolean;
}


export enum CARDS_MODE {
  HOME_COLLECTION_FEED = 'home-collection-feed',
  MODEL_CARD_FEED = 'model-card-feed',
  EDIT_CARD = 'edit-card',
  COMPOSITE_FEED = 'composite-feed'
}

export interface ICardFeedModificator {
  isFeedForEditCollection?: boolean;
  hiddenOptionsButton?: boolean;
}

export interface NoDataMessage {
  text: string;
  icon?: string;
}

export interface INavpanelAction {
  actionName: string;
  actionType: NAVPANEL_ACTION_TYPE;
  isSelected?: boolean;
  optionalData?: any;
}

export interface ICardAction {
  card: ICard;
  controlAction: CARD_CONTROL_BTN;
}

export enum NAVPANEL_ACTION_TYPE {
  MAKE_COLLABORATIVE = 'make-COLLABORATIVE',
  MAKE_NON_COLLABORATIVE = 'make-non-COLLABORATIVE',
  EDIT_COLLABORATORS = 'edit-collaborators',
  EDIT_COLLECTION = 'edit-collection',
  CANCEL_PUBLISHING = 'cancel-publish',
  DELETE_COLLECTION = 'delete-collection',
  UNLIKE_COLLECTION = 'unlike-collection',
  UPLOAD_MODEL = 'upload-model',

  UNLIKE_CARD = 'unlike-card',

  UPLOAD_MODEL_NOT_SUBSCRIBED = 'upload-model-not-subscribed',
  UPLOAD_MODEL_LIMIT_REACHED = 'upload-model-limit',
  TRIAL_EXPIRED = 'trial-expired',
  UPLOAD_MODEL_DISABLED = 'upload-model-disabled',

  ADD_FROM_LIBRARY = 'add-from-library',
  CLOSE_NAVPANEL = 'close-navpanel',
  CONTACT_US = 'contact-us',
  LINK_PAYMENTS = 'link-payments',
  RECENTLY_DELETED = 'recently-deleted',
  PURCHASES = 'purchases',

  TEST_BUY_SUBSCRIPTION = 'test-buy-subscription',
  TEST_BUY_CONSUMABLE = 'test-buy-consumable',

  SHOW_SUBSCRIBE = 'show-subscribe',

  STRIPE_PAYMENT = 'stripe-payment',
  STRIPE_HOSTED_CHECKOUT = 'stripe-hosted-checkout',
  STRIPE_CANCEL = 'stripe-cancel',

  FREE_TRIAL_ACTIVATION = 'free-trial-activation',

  FILTER_PURCHASES = 'filter-purchases',

  FILTER_ALL_MODELS = 'filter-all-models',
  FILTER_SAVED_MODELS = 'filter-saved-models',
  FILTER_UPLOADED_MODELS = 'filter-uploaded-models',
  FILTER_PURCHASE_MODELS = 'filter-purchase-models',
  FILTER_REVIEW_MODELS = 'filter-review-models',

  BUY_MODELS = 'buy-models',

  DOWNLOAD_MODELS = 'download-models',

  PUBLISH_MODELS = 'publish-models',
  PUBLISH_MODELS_DISABLED = 'publish-models-disabled',


  LIKE_COLLECTION = 'like-collection',
  SHARE_COLLECTION = 'share-collection',
  SHARE_MODEL = 'share-model',


  APPROVE_COLLECTION = 'approve-col',
  REJECT_COLLECTION = 'reject-col',

  SETTINGS = 'settings',
  SIGN_OUT = 'sign-out',
  VIEW_ACCOUNT = 'view-account',
  NEW_COLLECTION = 'new-collection',
  CREATE_COLLECTION_DONE = 'create-collection-done',

  ADD_VIDEO = 'add-video',
  ADD_DESCRIPTION = 'add-desc',

  DELETE_CARD_IN_REVIEW = 'delete-card-in-review',
  REMOVE_CARD_FROM_COLLECTION = 'remove-card-from-collection',
  DELETE_CARD = 'delete-card',
  RENAME_CARD = 'rename-card',
}


// ПРОДОЛЖИТЬ С ЭТОГО МЕСТА

export enum LIBARY_MODE {
  DEFAULT = 'default',
  SEE_ALL = 'see-all',
  PUBLISH_REJECTED_REVIEW = 'publish-rejected-review',
  SEARCH = 'search',
}

export enum SWITCH_PANEL_ITEM {
  UPLOADS = 'uploads',
  LIKES = 'likes',
  PURCHASES = 'purchases',
  ALL = 'all',
  HIDDEN = 'hidden',
  IN_REVIEW = 'in-review',
  REJECTED = 'rejected',
  PUBLISHED = 'published'
  // PORTFOLIO = 'portfolio',
  // LIKES = 'likes',
  // PURCHASES = 'purchases',
  // REVIEW = 'review'
}

export enum CARD_CONTROL_BTN {
  MOVE = 'move',
  SEARCH = 'search',
  CONTROL_BACK_BTN = 'back-btn',
  CONTROL_GO_TO_DETAILS = 'go-to-details',
  CONTROL_ACTIONS_DEFAULT = 'control-actions-default',
  CONTROL_ACTION_FOR_LIKED = 'control-for-actions-liked',
  CONTROL_ACTIONS_FOR_UNLIKED = 'control-for-actions-unliked',

  CONTROL_ACTIONS_FOR_PURCHASED = 'control-actions-purchased',
  CONTROL_ACTION_FOR_EDIT_CARD = 'control-actions-for-edit-card',
  CONTROL_ACTION_FOR_PUBLISHED = 'control-actions-for-publ',
  CONTROL_ACTION_FOR_REVIEW_PUBLISH_REJECT = 'control-actions-for-review-publ-reject',

  CONTROL_ACTION_FOR_IN_REVIEW = 'control-action-for-in-review',
  CONTROL_ACTION_REJECTED = 'control-action-rejected',
  CONTROL_ACTION_FOR_UPLOADED = 'control-action-for-uploaded',
  CONTROL_ACTION_MORE_FOR_REJECTED = 'control-action-more-for-rejected',
  CANCEL_PUBLISH = 'cancel-publishing',
  PUBLISH_SINGLE_CARD = 'publish-single-card',
  LIKE_DRAFT_CARD = 'like-draft-card',
  UNLIKE_DRAFT_CARD = 'unlike-draft-card',
  DELETE_MODEL = 'delete-model',
  BUY_MODEL = 'buy-model',
  RENAME_CARD = 'rename-card-action',
  REMOVE_CARD_FROM_COLLECTION = 'remove-card-from-collection',
  ANONYMOUS_USER = 'anonymous-user',
}

export enum COLLECTION_CONTROL_BTN {
  EDIT = 'edit',
  EDIT_CHANGE_VIDEO = 'edit-change-video',
  EDIT_ADD_DESCRIPTION = 'edit-add-description',
  BUY_MODELS = 'buy-models',
  FILTER_MODELS = 'filter-models',
  DOWNLOAD_MODELS = 'download-models',
  COLLABORATIVE_SHARE = 'colloborative-share',

  ANONYMOUS_USER = 'anonymous-user',
}

export enum GALLERY_MODE {
  DEFAULT = 'Default',
  SELECTION = 'Selection',
  AGGREGATED_STATIC = 'Aggragated-static',
  COLLECTIONS_NON_COMPOSITE = 'Collections-non-composite',
}

export interface FirebaseModelDto {
  cardContentMobile: string;
  cardPosterMobile: string;
  cardPosterWeb: string;
  cardContentWeb: string;
  loadProgress: number;
  loadStatus: string;
  model: string;
  userID: string;
  id: string;
  parentCollectionId: string;


}

