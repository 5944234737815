import { UtilityFunctionsService } from './utility-functions.service';
import { JsonDtoICollection, ICardCollection, DtoICollection, DtoICard } from './../../models/collection';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { JsonDtoICard, ICardLoadProgress, ICardLoadProgressProcessed, PRELOAD_DIRECTION, ModelCardRendered, ICardExtended, StripeSubscriptionData, SUBSCRIPTION_STATUS, UserSubscriptionStatusDto, StripePrices } from './../../models/card';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, Subject, Subscription, Observable, every, BehaviorSubject, lastValueFrom } from 'rxjs';
import { CARD_TYPE, ICard, CARD_PURCHASE_STATUS } from 'src/app/models/card';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FirebaseAuthService, PURCHASE_PROCESS } from './firebase-auth.service';
import { SettingsService } from './settings.service';
import { IappurchaseService } from './iappurchase.service';
import { RollbarService, CustomRollbar } from './rollbarlog.service';
import { User } from 'firebase/auth';


@Injectable({
  providedIn: 'root'
})
export class CardsService {
  public usdzHref: string = 'blank';

  private currentVisibleCard: ICard;
  private lastCollectionCard: ICard;

  public isFullscreenPreviewVisible: boolean = false;
  public currentCardSubject: Subject<ICard> = new Subject();
  public moreBtnSubject: Subject<ICard> = new Subject();
  public lessBtnSubject: Subject<any> = new Subject();
  public fullscreenPreviewSubject: Subject<boolean> = new Subject();
  public onApplicationActivationSubject: Subject<ICard> = new Subject();
  public onCardChangedInVisibleZone: Subject<ICard[]> = new Subject();
  public onCardPurchaseStateChange: BehaviorSubject<PURCHASE_PROCESS> = new BehaviorSubject(PURCHASE_PROCESS.DEFAULT);
  public onSubscriptionPurchaseStateChange: Subject<PURCHASE_PROCESS> = new Subject();

  public stripeSubscriptionPrices: StripePrices[];


  public initStartTime: number;
  public initHomePageStartTime: number;
  public initHomeBeforeRequestsStartTime: number;
  public initHomePageEndTime: number;
  public homeFirebaseAuthTime: number;
  public homeFeedGetTime: number;
  public homeBasicInitTime: number;

  public collectionDetailsInitTimeStart: number;
  public collectionDetailsInitTimeEnd: number;

  public modelFeedInitStart: number;
  public modelFeedInitEnd: number;

  public initFeedPageStartTime: number;
  public initFeedPageEndTime: number;
  public initEndTime: number;
  public afterFeedInitTime: number;

  public cardProgressStats: ICardLoadProgress[] = [];

  private preloadCardsArray: HTMLVideoElement[];

  public isReversePreloadDirectionSet: boolean = false;
  private lastTriggered: Date | null = null;

  // REFACTOR LATER !!!!!

  public onCollectionFeedDataRecieved: Subject<ICardCollection[]> = new Subject();
  public firebaseAuthSubscription: Subscription;
  public currentUser: User;
  public feedCollectionCardsSource: ICardCollection[];
  private currentPurchaseIds: string[];
  private currentModelsIds: string[];

    // eslint-disable-next-line max-len
  constructor(
    private http: HttpClient,
    public firebaseAuth: FirebaseAuthService,
    public settingsService: SettingsService,
    public utilityService: UtilityFunctionsService,
    public mockDataService: MockDataService,
    public iappurchaseService: IappurchaseService,
    @Inject(RollbarService) private rollbar: CustomRollbar
    ) {
      this.getHrefUsdzSample().then((refUrl)=>{
        this.usdzHref = refUrl
      })

      this.iappurchaseService.nativeIndividualPurchaseSubscription.subscribe((process) => {
        this.triggerCardPurchaseStateChange(process);
      })

      this.iappurchaseService.nativeSubscriptionPurchaseSubscription.subscribe((process) => {
        switch (process) {
          case PURCHASE_PROCESS.DEFAULT:
            console.log('SUBSCRIPTION PURCHASE_PROCESS.DEFAULT')
            this.onSubscriptionPurchaseStateChange.next(PURCHASE_PROCESS.DEFAULT);
            break;
          case PURCHASE_PROCESS.ERROR:
            console.log('SUBSCRIPTION PURCHASE_PROCESS.ERROR')
            this.onSubscriptionPurchaseStateChange.next(PURCHASE_PROCESS.ERROR);
            break;
          case PURCHASE_PROCESS.PROCESSING:
            console.log('SUBSCRIPTION PURCHASE_PROCESS.PROCESSING');
            this.onSubscriptionPurchaseStateChange.next(PURCHASE_PROCESS.PROCESSING);
            break;
          case PURCHASE_PROCESS.SUCCESS:
            console.log('SUBSCRIPTION PURCHASE_PROCESS.SUCCESS');
            this.onSubscriptionPurchaseStateChange.next(PURCHASE_PROCESS.SUCCESS);
            break;
          default:
            break;
        }
      })
    }


public async collectionFeedInit(): Promise<void> {
  await new Promise<void>(async (resolve, reject) => {
      if(this.firebaseAuth.currentUser) {
        await this.doFullAuth(this.firebaseAuth.currentUser);
        this.setSubForStatusSubscription();
        resolve();
      } else {
        // this.firebaseAuth.firebaseSignInAnonymously();
        try {
          this.getHomeFeed({unauth: true}).then((resp) => {
            this.feedCollectionCardsSource = resp;
            this.onCollectionFeedDataRecieved.next(this.feedCollectionCardsSource);

          })
        } catch (error) {
        }
        this.firebaseAuthSubscription = this.firebaseAuth.firebaseUser.subscribe(async (usr) => {
          await this.doFullAuth(usr);
          // getting STRIPE for web payments
          if(environment.type === 'web') {
            this.getStripeSubscriptions().then((resp: StripeSubscriptionData[]) => {
              let targetPrices: StripePrices[];
              targetPrices = resp.find((el) => el.is_actual)?.prices;
              if(targetPrices === undefined) targetPrices = resp[0].prices;

              this.stripeSubscriptionPrices = targetPrices;
              console.log('getStripeSubscriptions: ', targetPrices);
            });
          }

          this.firebaseAuthSubscription.unsubscribe();
          this.setSubForStatusSubscription();
          resolve();
        });
      }
  });

  this.feedCollectionCardsSource = await this.getHomeFeed();
  this.onCollectionFeedDataRecieved.next(this.feedCollectionCardsSource);
}


public checkUserSubscriptionStatus(): SUBSCRIPTION_STATUS {
  return this.firebaseAuth.currentUser.subscriptionStatus
}

public setSubForStatusSubscription(): Promise<SUBSCRIPTION_STATUS> {
  return new Promise<SUBSCRIPTION_STATUS>((resolve, reject) => {
    console.log('****** Requesting user subscription status ')
    this.getUserSubscriptionStatusRequest().then((resp: UserSubscriptionStatusDto) => {
      // MAKE SURE RESPONSE IS CORRECT;
      this.firebaseAuth.patch3DWayUser(resp);
      resolve(resp.user_status);
    }).catch(err => {
      this.utilityService.showErrorSnackBar(err, 700);
      setTimeout(() => {
        this.utilityService.showImportantErrorSnackBar('Something wrong with checking your subscription status, please contact support', 700);
        reject(err);
      }, 1000);
    })
  });
}

public async getCollectionFeed(): Promise<ICardCollection[]> {
  if(this.feedCollectionCardsSource) {
    return new Promise((res, reject) => {
      res(JSON.parse(JSON.stringify(this.feedCollectionCardsSource)));
    });
  } else {
    return new Promise((res, reject) => {
      const sub: Subscription = this.onCollectionFeedDataRecieved.subscribe((data) => {
        res(JSON.parse(JSON.stringify(this.feedCollectionCardsSource)));
        sub.unsubscribe();
      })
    });

  }
}

public convertCollectionsToCardsFeed(col: ICardCollection[]): ICard[] {
  let cards = []
  cards = col.map(collection => {
    return {
      id: collection.collectionId,
      cardPosterMobile: collection.collectionPosterMobile,
      cardContentMobile: collection.collectionContentMobile,
      cardPosterWeb: collection.collectionPosterWeb,
      cardContentWeb: collection.collectionContentWeb,
      cardType: CARD_TYPE.COLLECTION,
      cardAuthor: collection.collectionAuthor,
      cardTitle: collection.collectionTitle,
      cardDescription: collection.collectionDescription,
      cardPrice: 0,
      purchaseStatus: CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    }
  })
  return cards
}


private async doFullAuth(currentUser: User): Promise<void> {
  this.currentUser = currentUser;
  const authToken = await this.currentUser.getIdToken();
  // this.rollbar.info(`Full auth happened, user: ${this.currentUser.uid}`);
  await this.getSignedCookieForGCS(authToken);
  console.log(`Full auth happened, user: ${this.currentUser.uid}`)
}

  private async getSignedCookieForGCS(authToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + authToken,
        }),
        reportProgress: true,
        withCredentials: true
      };
      lastValueFrom(this.http.get(environment.endpoints.authUrl, httpOptions))
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
        console.error(err);
        this.firebaseAuth.clearLocalStorageData();
        this.settingsService.showToastMsg(err);
      })
    });
  }

  public getCollectionCardSourceByTitle(contentUrl: string): ICardCollection {
    const requiredCollection = this.feedCollectionCardsSource.find((cardToCheck) => {
      return cardToCheck.collectionContentMobile === contentUrl;
    })
    return requiredCollection;
  }

  public getCollectionFeedCardsById(id: string): ICard[] {
    const requiredCollection = this.feedCollectionCardsSource.find((cardToCheck) => {
      return cardToCheck.collectionId === id;
    })
    return requiredCollection.collectionCards;
  }

  public getCollectionFromHomeFeedById(id: string): ICardCollection {
    const requiredCollection = this.feedCollectionCardsSource.find((cardToCheck) => {
      return cardToCheck.collectionId === id;
    })
    return requiredCollection
  }

  public getCardById(id: string): ICard {
    let foundCard: ICard;
    const mergedCollectionCards: ICard[] = this.feedCollectionCardsSource.reduce(
      (acc, curr) => {
        return [...acc, ...curr.collectionCards];
      },
      []
    );
    mergedCollectionCards.forEach((card) => {
        if(card.id === id) {
          foundCard = card;
        }
      })
    return foundCard
  }

  public setLastCollectionCard(collectionCard: ICard): void {
    this.lastCollectionCard = collectionCard;
  }

  public getLastCollectionCard(): ICard {
    return this.lastCollectionCard;
  }

  public resetCardService(): void {
    this.currentVisibleCard = undefined;
    this.preloadCardsArray = [];
    this.cardProgressStats = [];
  }

  public setCurrentCard(card: ICard): void {
    console.log(card.cardTitle, ' ', performance.now());
    if (this.currentVisibleCard !== card) {
      // Check if card types match
      if (this.currentVisibleCard?.cardType === card.cardType) {
        const changedCards: ICard[] = [];
        changedCards.push(this.currentVisibleCard);
        changedCards.push(card);
        this.onCardChangedInVisibleZone.next(changedCards);
        console.log('card changed!');
        this.userScrolledAtLeastOnce();
      } else {
        console.log('Card types do not match, not firing onCardChangedInVisibleZone');
      }
    }
    this.currentVisibleCard = card;
    this.currentCardSubject.next(this.currentVisibleCard);
  }

  public getCurrentCard(): ICard {
    return this.currentVisibleCard;
  }

  public triggerAppActivated(): void {
    this.onApplicationActivationSubject.next(this.currentVisibleCard);
    console.log('App activated!');

    const now = new Date();

    if (this.lastTriggered) {
      const timeDifference = now.getTime() - this.lastTriggered.getTime();
      const oneHour = 60 * 60 * 1000;

      if (timeDifference > oneHour) {
        const timeToWait = oneHour - timeDifference;

        this.lastTriggered = now;  // This line moved here

        setTimeout(() => {
          console.log('Triggered checking subscription status on app activation');
          // this.rollbar.info('RB: Triggered checking subscription status on app activation');
          this.setSubForStatusSubscription();
        }, timeToWait);

        return;
      }
    } else {
      this.lastTriggered = now;
    }
  }

  public triggerMoreBtnSubject(): void {
    this.moreBtnSubject.next(this.currentVisibleCard);
  }

  public triggerLessBtnSubject(): void {
    this.lessBtnSubject.next(this.currentVisibleCard);
  }

  public triggerShowFullscreen(): void {
    this.isFullscreenPreviewVisible = true;
    this.fullscreenPreviewSubject.next(true);
  }

  public triggerHideFullscreen(): void {
    this.isFullscreenPreviewVisible = false;
    this.fullscreenPreviewSubject.next(false);
  }

  public triggerToggleFullscreenSubject(): void {
    this.isFullscreenPreviewVisible = !this.isFullscreenPreviewVisible;
    this.fullscreenPreviewSubject.next(this.isFullscreenPreviewVisible);
  }

  public async triggerCardPurchaseStateChange(newState: PURCHASE_PROCESS) {
    switch (newState) {
      case PURCHASE_PROCESS.DEFAULT:
        console.log('PURCHASE_PROCESS.DEFAULT')
        this.onCardPurchaseStateChange.next(PURCHASE_PROCESS.DEFAULT);
        break;
      case PURCHASE_PROCESS.ERROR:
        console.log('PURCHASE_PROCESS.ERROR')
        this.onCardPurchaseStateChange.next(PURCHASE_PROCESS.ERROR);
        break;
        case PURCHASE_PROCESS.VERIFICATION:
          console.log('PURCHASE_PROCESS.VERIFICATION');
          this.onCardPurchaseStateChange.next(PURCHASE_PROCESS.VERIFICATION);
          break;
      case PURCHASE_PROCESS.PROCESSING:
        console.log('PURCHASE_PROCESS.PROCESSING');
        this.onCardPurchaseStateChange.next(PURCHASE_PROCESS.PROCESSING);
        break;
      case PURCHASE_PROCESS.SUCCESS:
        console.log('PURCHASE_PROCESS.SUCCESS');
        console.log('PURCHASE_IDs: ', this.currentPurchaseIds);
        let resp;
        try {
          resp = await this.postPurchaseConfirmation(this.currentModelsIds);
        } catch (error) {
          console.log('ERROR postPurchaseConfirmation;')
          console.log(error)
          if (error.error) this.utilityService.showErrorSnackBar(JSON.stringify(error.error), 3000)
          else this.utilityService.showErrorSnackBar(JSON.stringify(error), 3000);
        }
        console.log('RESPONSE: ', resp);
        if(resp?.status === 'confirmed') {
          this.onCardPurchaseStateChange.next(PURCHASE_PROCESS.SUCCESS);
        } else {
          if(resp) this.utilityService.showErrorSnackBar(JSON.stringify(resp), 3000);
          // else this.utilityService.showErrorSnackBar('Unknown error, please, contact support', 3000);
          this.onCardPurchaseStateChange.next(PURCHASE_PROCESS.ERROR);
        }

        break;

      default:
        break;
    }
  }

  public preloadNextCards(nodesToPreload: HTMLVideoElement[], currentNode: HTMLVideoElement): void {
    this.preloadCardsArray = nodesToPreload;
    if(currentNode.duration && currentNode.buffered.length > 0 && currentNode.buffered.end(0) / currentNode.duration > 0.2) this.startPreload();
  }

  public addCardsToPreload(nodesToPreload: HTMLVideoElement[]): void {
    if (nodesToPreload?.length > 0) {
      nodesToPreload.forEach((nodeToPreload) => {
        this.preloadCardsArray.push(nodeToPreload);
      })

      window.setTimeout(() => {
        this.startPreload();
      }, 100);
      console.log('** PRELOAD ARRAY LENGTH: ', this.preloadCardsArray.length, ' ** ');
    }
  }

  public onVideoLoadFinished(cardLoadProgress: ICardLoadProgress) {
    this.cardProgressStats.push(cardLoadProgress);
    this.startPreload();
  }

  public setPreloadDirection(direction: PRELOAD_DIRECTION): void {
    if(direction === PRELOAD_DIRECTION.BACKWARD) this.isReversePreloadDirectionSet = true
    else this.isReversePreloadDirectionSet = false;
  }

  public startPreload(): void {
    if (!this.preloadCardsArray) return;
    const videoArray = this.preloadCardsArray.slice();
    for (const video of videoArray) {
      if (isNaN(video.duration) || video.buffered.length === 0 || video.buffered.end(0) < video.duration * 0.95) {
        video.play().catch((err) => {
          console.warn(err);
        });
        console.log('** CARD THAT STARTED TO PRELOAD: ', video?.src, video.classList.toString());
        window.setTimeout(() => {
          if (this.currentVisibleCard?.cardContentMobile !== video.currentSrc) {
            video.pause();
          }
        }, 25);
        break;
      }
    }
  }


  public processCardStats(): ICardLoadProgressProcessed[] {
    const statsProcessed: ICardLoadProgressProcessed[] = [];
    this.cardProgressStats.forEach((cardStat) => {
      const cardProgressStat: ICardLoadProgressProcessed = {
        cardNameShort: '',
        timeToStartPlaying: 0,
        timeToFullLoad: 0,
        percentOfVideoToStartPlaying: 0,
      };
      // PROCESS NAME TO BE REALLY SHORT;
      const nameStartPos = cardStat.cardName.indexOf('/') + 1;
      const shortName = cardStat.cardName.substring(nameStartPos,nameStartPos + 2);

      cardProgressStat.cardNameShort = shortName
      cardStat.progress.forEach((progress) => {
        if(progress.eventName === 'CardCanPlayThrough') {
          cardProgressStat.timeToStartPlaying = progress.time;
          cardProgressStat.percentOfVideoToStartPlaying = progress.progressMark;
        }
        if(progress.progressMark === 1) {
          cardProgressStat.timeToFullLoad = progress.time;
        }
      })

      statsProcessed.push(cardProgressStat);
    })
    return statsProcessed
  }

  public async processConsumableInAppPurchaseRequest(cards: ModelCardRendered[]): Promise<void> {
    let purchaseDetails;
    console.log('processConsumableInAppPurchaseRequest REQUESTED')
    if(this.onCardPurchaseStateChange.getValue() === PURCHASE_PROCESS.PROCESSING) return;
    console.log('processConsumableInAppPurchaseRequest STARTED')
    this.triggerCardPurchaseStateChange(PURCHASE_PROCESS.PROCESSING);
    const uid = await this.firebaseAuth.getUserUid()

    try {
      this.rollbar.info(`cardPurchaseIntent for user ${uid}`)
      purchaseDetails = await this.postRequestPurchaseIntent(cards);
    } catch(err) {
      if(err.error.error) {
        this.utilityService.showErrorSnackBar(JSON.stringify(err.error.error), 2000);
        this.rollbar.error(`cardPurchaseIntent ERROR, user ${uid}`, err)
      } else {
        this.utilityService.showErrorSnackBar(JSON.stringify(err), 2000);
        this.rollbar.error(`cardPurchaseIntent ERROR, user ${uid}`, err)
      }
      this.triggerCardPurchaseStateChange(PURCHASE_PROCESS.ERROR);
      return
    }

    if(purchaseDetails?.model_available && purchaseDetails?.product_id) this.buyConsumable(purchaseDetails.purchase_id, cards, purchaseDetails.product_id);
    else {
      this.triggerCardPurchaseStateChange(PURCHASE_PROCESS.DEFAULT);
      this.utilityService.showErrorSnackBar('Purchase request failed: ' + purchaseDetails, 2000)
      this.rollbar.error(`Purchase request failed , user ${uid}`, purchaseDetails)
    }
  }


  public buyConsumable(purchaseIds: string[], cards: ModelCardRendered[], productId: string): void {
    this.currentPurchaseIds = purchaseIds;
    this.currentModelsIds =  cards.map(card => card.id);

    try {
      this.iappurchaseService.activateConsumablePurchase(cards, productId).catch((error) => {
        this.utilityService.showErrorSnackBar(error.toString(), 2000);
        console.error(error);
        this.rollbar.error('Error in buy consumable ', error)
        this.triggerCardPurchaseStateChange(PURCHASE_PROCESS.ERROR)
      })
      // this.store.order('generic.3dmodel.purchase', {applicationUsername: this.firebaseAuth.currentUser.uid}).then((resp) => {
      //   console.log(resp);
      // }).error((err) => {
      //   console.error(err);
      //   this.triggerCardPurchaseStateChange(PURCHASE_PROCESS.ERROR)
      // });
    } catch (error) {
      this.utilityService.showErrorSnackBar(error.toString(), 2000);
      console.error(error);
      this.rollbar.error('Error in buy consumable ', error)
      this.triggerCardPurchaseStateChange(PURCHASE_PROCESS.ERROR)
    }

  }

  public async getHrefUsdzSample(): Promise<string> {
    return this.firebaseAuth.getDownloadUrl(this.mockDataService.sampleUSDZmodelGSpathPreview)
  }

  public async setRenderLimit(userId: string, renderLimit: number): Promise<any> {
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {
      'user_id': userId,
      'render_limit': renderLimit
    };
    let response;
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.upload}/set_render_limit`, postBody, this.getRequestOptions(userToken)));
      this.firebaseAuth.setRenderLimitCache(renderLimit);
      console.log('Render limit set successfully: ', response);
    } catch (error) {
      console.log('setRenderLimit ERROR FULL: ', JSON.stringify(error));
      const errMsg = error?.error?.status || error.error;
      console.log('setRenderLimit ERROR: ', errMsg);
      throw new Error(errMsg); // выбросить новую ошибку
    }

    return response;
  }

  public async getStripeSubscriptions(): Promise<any> {
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {}

    let response
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseWeb}/get_stripe_subscriptions`, postBody, this.getRequestOptions(userToken)));
      console.log('response get_stripe_subscriptions', response)
    } catch (error) {
      if(error?.error?.error) {
        console.log('get_stripe_subscriptions: ', error.error.error);
        return error.error.error;
      } else return JSON.stringify(error.error) as any;
    }
    return response
  }

  public async postRequestPurchaseIntent(cards: ICard[]): Promise<number> {
    const userToken = await this.firebaseAuth.getUserToken();
    const uid = await this.firebaseAuth.getUserUid();
    const cardsId = cards.map(card => card.id);
    const price = cards.reduce((acc, val) => {
      return acc = acc + val.cardPrice;
    }, 0)
    const postBody = {'model_ids': cardsId , 'user_id': uid, price }
    let response
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseIos}/validate_purchase`, postBody,  this.getRequestOptions(userToken)));
      console.log('response validate_purchas', response)
    } catch (error) {
      if(error?.error?.error) {
        console.log('postRequestPurchaseIntent: ', error.error.error);
        return error.error.error;
      } else return JSON.stringify(error.error) as any;
    }
    return response
  }

  public async postPurchaseConfirmation(cardIds: string[]): Promise<any> {
    const uid = await this.firebaseAuth.getUserUid();
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {'model_ids': [...cardIds] , 'user_id': uid , 'app_store_receipt': this.iappurchaseService.lastPurchaseAppStoreReciept }

    let response
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseIos}/confirm_purchase`, postBody, this.getRequestOptions(userToken)));
      console.log('response confirm_purchase', response)
    } catch (error) {
      if(error?.error?.error) {
        console.log('purchaseConfError: ', error.error.error);
        return error.error.error;
      } else return JSON.stringify(error.error) as any;
    }
    return response
  }

  public async getListOfUserPurchasedModels(): Promise<any> {
    // getUserPurchasesModels
    const uid = await this.firebaseAuth.getUserUid();
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {'user_id': uid}
    let response: any = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseIos}/purchased_models`, postBody, this.getRequestOptions(userToken)));
    if(response === null) response = [];
    console.log('getListOfUserPurchasedModels : ', response);
    return response
  }



  public async createPurchaseIntent(): Promise<any> {
    // getUserPurchasesModels
    const uid = await this.firebaseAuth.getUserUid();
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {
      'priceId': 'price_1P3uWL2K1MDKKgy5GUWiPP9i',
      'quantity':1200,
      'user_id': uid,
      'paymentMethodTypes':[
         'card'
      ]
   }
    let response: any = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseWeb}/create-payment-intent`, postBody, this.getRequestOptions(userToken)));
    if(response === null) response = [];
    console.log('getListOfUserPurchasedModels ', response);
    return response
  }

  public async createCheckOutSession(): Promise<any> {
    // getUserPurchasesModels
    const uid = await this.firebaseAuth.getUserUid();
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {
      'prices': this.stripeSubscriptionPrices,
      'quantity':1,
      'user_id': uid,
      'paymentMethodTypes':[
         'card'
      ]
   }
   console.log('postBody: ', postBody);
    let response: any = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseWeb}/create-checkout-session-embeded`, postBody, this.getRequestOptions(userToken)));
    if(response === null) response = [];
    console.log('createCheckOutSession ', response);
    return response
  }

  public async stripeCancelSubscription(): Promise<any> {
    const uid = await this.firebaseAuth.getUserUid();
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {
      'user_id': uid,
   }
   let response;
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseWeb}/cancel_stripe_subscription`, postBody, this.getRequestOptions(userToken)));
      console.log('stripeCancelSubscription ', response);
      this.utilityService.showImportantSnackBar('Your subscription cancelled', 100);
      return response
    } catch (error) {
      const errMsg = error?.error?.status  || error.error;
      console.log('stripeCancelSubscription ERROR: ', errMsg);
      throw new Error(errMsg); // выбросить новую ошибку
    }

  }

  public async stripeRenewSubscription(): Promise<any> {
    const uid = await this.firebaseAuth.getUserUid();
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {
      'user_id': uid,
   }
   let response;
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.purchaseWeb}/renew_stripe_subscription`, postBody, this.getRequestOptions(userToken)));
      console.log('stripeRenewSubscription ', response);
      this.utilityService.showImportantSnackBar('Your subscription renewed', 100);
      return response
    } catch (error) {
      const errMsg = error?.error?.status  || error.error;
      console.log('stripeRenewSubscription ERROR: ', errMsg);
      throw new Error(errMsg); // выбросить новую ошибку
    }

  }


  private getRequestOptionsAnonymous(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      }),
    };

    return httpOptions;
  }

  private getRequestOptions(token: string): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
      }),
      reportProgress: true,
      withCredentials: true
    };
    return httpOptions;
  }

  // PUBLISH !!!
  public async sendToReviewModelPostRequest(card: ICard): Promise<any> {
    const userToken = await this.firebaseAuth.getUserToken();
    // const postBody = {'purchase_id': this.currentPurchaseId , 'app_store_receipt': this.firebaseAuth.lastPurchaseAppStoreReciept }
    const postBodyProcessCards = this.convertCardsToDtoCards([card]);
    const postBody = {...postBodyProcessCards[0]} as any
    postBody.model_id = card.id;

    let response
    try {
      response = await lastValueFrom(this.http.post(`${environment.endpoints.contentReview}/create_review`, postBody, this.getRequestOptions(userToken)));
      this.utilityService.showSnackBar('Models successfully sent to review', 0);
      console.log('response sendToReviewModelPostRequest', response);
    } catch (error) {
      const errMsg = error?.error?.status  || error.error;
      console.log('sendToReviewModelPostRequest ERROR: ', errMsg);
      throw new Error(errMsg); // выбросить новую ошибку
    }

    return response
  }

  public async getUserReviewPublishRejectModels(): Promise<any> {
    const userToken = await this.firebaseAuth.getUserToken();
    const uid = await this.firebaseAuth.getUserUid();
    const postBody = {'user_id': uid}
    const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.contentReview}/user_reviews`, postBody, this.getRequestOptions(userToken)));
    const processedResponse = await this.processCardsFromDtoAsyncWithDownloadLink(response, true);
    console.log('getUserReviewPublishRejectModels Recieved : ', response);
    return processedResponse
}

  public async getUserReviewPublishRejectModelById(modelId: string): Promise<any> {
    const userToken = await this.firebaseAuth.getUserToken();
    const postBody = {
      'public_model_id': modelId,
    }

    const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.contentReview}/get_review_by_public_model_id`, postBody, this.getRequestOptions(userToken)));
    console.log('getUserReviewPublishRejectModelById Recieved : ', response);
    return response
    }

    public async cancelReviewModelPostRequest(model?: ICard): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();
      const postBody = {'public_model_id': model.publicModelId}

      const response = await lastValueFrom(this.http.post(`${environment.endpoints.contentReview}/del_review`, postBody, this.getRequestOptions(userToken)));

      return response
    }

    public async getAllUserLikes(): Promise<any> {
      const uid = await this.firebaseAuth.getUserUid();
      const userToken = await this.firebaseAuth.getUserToken();

      const postBody = {'user_id': uid}

      const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/likes`, postBody, this.getRequestOptions(userToken)));
      console.log('getAllUserLikes Recieved : ', response);
      return response
    }

    public async setUserLike(model: ICard | ICardCollection): Promise<any> {
      const uid = await this.firebaseAuth.getUserUid();
      const userToken = await this.firebaseAuth.getUserToken();

      let body;
      if(typeof model === 'object' && 'cardContentMobile' in model) {
        body = {
          'item_id': model.id,
          'item_type' : CARD_TYPE.MODEL,
          'user_id': uid,
        }
      }
      if(typeof model === 'object' && 'collectionId' in model) {
        body = {
          'item_id': model.collectionId,
          'item_type' : CARD_TYPE.COLLECTION,
          'user_id': uid,
        }
      }

      let response;
      try {
         response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/like`, body, this.getRequestOptions(userToken)));
          console.log('setUserLike Recieved : ', response);

      } catch (error) {
        console.log('ERROR setUserLike;')
        console.log(error)
        if (error.error) this.utilityService.showErrorSnackBar(JSON.stringify(error.error), 1500)
        else this.utilityService.showErrorSnackBar(JSON.stringify(error), 1500);
      }
      return response
    }

    public async removeUserLike(model: ICard | ICardCollection ): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();

      let body;
      if(typeof model === 'object' && 'cardContentMobile' in model) {
        body = {
          'item_id': model.id,
          'item_type' : CARD_TYPE.MODEL,
          'user_id': uid,
        }
      }
      if(typeof model === 'object' && 'collectionId' in model) {
        body = {
          'item_id': model.collectionId,
          'item_type' : CARD_TYPE.COLLECTION,
          'user_id': uid,
        }
      }
      const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/dislike`, body,  this.getRequestOptions(userToken)));
      console.log('removeUserLike Recieved : ', response);
      return response
    }

    public async getHomeFeed(options: { unauth?: boolean } = {}): Promise<ICardCollection[]> {
      const { unauth } = options;
      let userToken
      let response: any;

      if(unauth) {
        response = await lastValueFrom(this.http.get(`${environment.endpoints.webBackend}/home_feed`, this.getRequestOptionsAnonymous()))
      } else {
        userToken = await this.firebaseAuth.getUserToken();
        response = await lastValueFrom(this.http.get(`${environment.endpoints.webBackend}/home_feed`, this.getRequestOptions(userToken)));

      }
      const processedResponse = this.processCollectionDtoHomeFeedResponce(response);
      processedResponse.forEach(collection => {
        collection.collectionCards.forEach(card => {
          card.purchaseStatus = CARD_PURCHASE_STATUS.DEFAULT_3DWAY;
        });
      });
      console.log('getHomeFeed Recieved : ', response);
      return processedResponse
    }

      public async getUserUploadedModels(userId: string): Promise<ICard[]> {
        const userToken = await this.firebaseAuth.getUserToken();
        let response;
        const postBody = {'user_id': userId}
        try {
            response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/all_user_uploaded_models`, postBody, this.getRequestOptions(userToken)));
            if(response === null) response = [];
        } catch (error) {
          console.log(error)
          if (error.error) this.utilityService.showErrorSnackBar(JSON.stringify(error.error), 1500)
          else this.utilityService.showErrorSnackBar(JSON.stringify(error), 1500);
        }

        const processedResponse = await this.processCardsFromDtoAsyncWithDownloadLink(response, undefined);

        // Assuming modifiedDate is in a format that can be compared directly as strings (e.g., ISO 8601)
        // If modifiedDate is not in a directly comparable string format, you might need to parse it as a Date object first
        processedResponse.sort((a, b) => b.modifiedDate.localeCompare(a.modifiedDate));

        console.log('getListOfUserUploadedModels Download: ', processedResponse);
        return processedResponse;
    }


      public async getModelById(modelId: string): Promise<ICard[]> {
        const userToken = await this.firebaseAuth.getUserToken();
        let response;
        const postBody = {'model_id': modelId}
        try {
          response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/get_model`, postBody, this.getRequestOptions(userToken)));
        } catch (error) {
          console.log(error)
          if (error.error) this.utilityService.showErrorSnackBar(JSON.stringify(error.error), 1500)
          else this.utilityService.showErrorSnackBar(JSON.stringify(error), 1500);
        }
        console.log('getModelById : ', this.processCardsFromDtoHomeFeed([response], ''));
        return this.processCardsFromDtoHomeFeed([response], '')
      }

      public async createCollectionPostRequest(collection?: ICardCollection): Promise<any> {
        // const postBody = {'purchase_id': this.currentPurchaseId , 'app_store_receipt': this.firebaseAuth.lastPurchaseAppStoreReciept }
        const uid = await this.firebaseAuth.getUserUid();
        const userToken = await this.firebaseAuth.getUserToken();

        collection.collectionAuthor = uid;
        const postBody = { ...this.convertCollectionToDtoCollection(collection) } as any

        let response
        try {
          response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/create_collection`, postBody, this.getRequestOptions(userToken)));
          console.log('response createCollectionPostRequest', response)
        } catch (error) {
          if (error?.error?.error) {
            console.log('createCollectionPostRequest ERROR: ', error.error.error);
            return error.error.error;
          } else return JSON.stringify(error.error) as any;
        }
        return response
      }

      public async getUserDraftCollections(): Promise<any> {
        const userToken = await this.firebaseAuth.getUserToken();
        const uid = await this.firebaseAuth.getUserUid();
        const postBody = {'user_id': uid}
        // TEMPORARY USE DEFAULT ENDPOINT FOR ALL MODELS IN REVIEW/REJECT NOT RELATED TO USER;
        const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/user_collections`, postBody, this.getRequestOptions(userToken)));
        console.log('getUserCollections Recieved : ', response);
        return this.processDraftCollectionDtoResponse(response)
    }

    public async getCollectionByIdRequest(collecetionId: string): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      // TEMPORARY USE DEFAULT ENDPOINT FOR ALL MODELS IN REVIEW/REJECT NOT RELATED TO USER;
      const postBody = {'collection_id': collecetionId}
      const response: any = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/get_collection`, postBody, this.getRequestOptions(userToken)));
      console.log('getCollectionByIdRequest Recieved : ', response);
      return this.processDraftCollectionDtoResponse([response])
  }

    public async deleteUserCollection(collectionId: string): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
        // const postBody = {'purchase_id': this.currentPurchaseId , 'app_store_receipt': this.firebaseAuth.lastPurchaseAppStoreReciept }
        const postBody = {'collection_id': collectionId}

        const response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/del_collection`, postBody, this.getRequestOptions(userToken)));
        console.log('response deleteUserCollection', response)
        return response
    }

    public async updateCollectionPostRequest(collection?: ICardCollection): Promise<any> {
      const uid = await this.firebaseAuth.getUserUid();
      const userToken = await this.firebaseAuth.getUserToken();
      collection.collectionAuthor = uid;
      const postBody = {...this.convertCollectionToDtoCollection(collection)} as any

      const response = await lastValueFrom(this.http.put(`${environment.endpoints.webBackend}/upd_collection`, postBody, this.getRequestOptions(userToken)));

      return response
    }


    public async deleteModelPostRequest(model?: ICard): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();
      const postBody = {...this.convertCardsToDtoCards([model])[0]}

      const response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/del_model`, postBody, this.getRequestOptions(userToken)));

      // try {
      //   console.log('response updateCollectionPostRequest', response)
      // } catch (error) {
      //   if(error?.error?.error) {
      //     console.log('updateCollectionPostRequest ERROR: ', error.error.error);
      //     return error.error.error;
      //   }
      //   else return JSON.stringify(error.error) as any;
      // }
      return response
    }

    public async updateModelPostRequest(model?: ICard): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();
      const postBody = {...this.convertCardsToDtoCards([model])[0]}

      const response = await lastValueFrom(this.http.put(`${environment.endpoints.webBackend}/update_model`, postBody, this.getRequestOptions(userToken)));

      // try {
      //   console.log('response updateCollectionPostRequest', response)
      // } catch (error) {
      //   if(error?.error?.error) {
      //     console.log('updateCollectionPostRequest ERROR: ', error.error.error);
      //     return error.error.error;
      //   }
      //   else return JSON.stringify(error.error) as any;
      // }
      return response
    }

    public async sendItemForSharing(item: ICard | ICardCollection): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();
      let body;
      if(typeof item === 'object' && 'cardContentMobile' in item) {
        body = {
          'content_id': item.id,
          'user_id': uid,
          'content_type' : CARD_TYPE.MODEL
        }
        if(item.cardType === CARD_TYPE.COLLECTION) {
          body.content_type = CARD_TYPE.COLLECTION
        }
      }
      if(typeof item === 'object' && 'collectionId' in item) {
        body = {
          'content_id': item.collectionId,
          'user_id': uid,
          'content_type' : CARD_TYPE.COLLECTION
        }
      }

      const response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/sharing_statistic`, body, this.getRequestOptions(userToken)));

      return response

    }


    public async disableUserRequest(): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();

      const body = {'user_id': uid}
      const requestHeader = this.getRequestOptions(userToken);
      requestHeader.headers = requestHeader.headers.append('X-Firebase-Token', userToken);

      const response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/remove_user`, body, requestHeader));

      return response

    }

    public async stopRenderPostRequest(modelId): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
        const uid = await this.firebaseAuth.getUserUid();
        const postBody = {
          user_id: uid,
          model_id: modelId}
        const response = await lastValueFrom(this.http.post(`${environment.endpoints.webBackend}/stop_render`, postBody, this.getRequestOptions(userToken)));
        console.log('response stopRenderRequest', response);
        return response
    }

    public async activateTrialPostRequest(): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
        const uid = await this.firebaseAuth.getUserUid();
        const postBody = {user_id: uid}

        const response = await lastValueFrom(this.http.post(`${environment.endpoints.upload}/activate_trial`, postBody, this.getRequestOptions(userToken)));
        console.log('response activateTrialPostRequest', response);
        return response
    }

    public async getUserSubscriptionStatusRequest(): Promise<any> {
      const uid = await this.firebaseAuth.getUserUid();
      const userToken = await this.firebaseAuth.getUserToken();
      const body = {
        user_id: uid
      }
      let response
      try {
        response = await lastValueFrom(this.http.post(`${environment.endpoints.upload}/subscription_status`, body,  this.getRequestOptions(userToken)));
        console.log('response getUserSubscriptionStatusRequest', response);
      } catch (error) {
        console.log('getUserSubscriptionStatusRequest ERROR FULL: ', JSON.stringify(error));
        const errMsg = error?.error?.status  || error.error;
        console.log('getUserSubscriptionStatusRequest ERROR: ', errMsg);
        throw new Error(errMsg); // выбросить новую ошибку
      }
      return response
  }

    public async getUploadStatusRequest(): Promise<any> {
      const userToken = await this.firebaseAuth.getUserToken();
      const uid = await this.firebaseAuth.getUserUid();

      const response = await lastValueFrom(this.http.get(`${environment.endpoints.upload}/${uid}/status`, this.getRequestOptions(userToken)));
      console.log('response getUploadStatusRequest', response);
      return response
  }



  // PROCESSING FUNCTIONS //

  private processCollectionDtoHomeFeedResponce(rawResponse: DtoICollection[]): ICardCollection[] {
    return rawResponse.map(({ collection_id, collection_preview_link_app, collection_poster_link_app, collection_preview_link_web, collection_poster_link_web, author_id, title, description, collection_models }) => ({
      collectionId: collection_id,
      collectionContentMobile: collection_preview_link_app,
      collectionPosterMobile: collection_poster_link_app,
      collectionContentWeb: collection_preview_link_web,
      collectionPosterWeb: collection_poster_link_web,
      accessType: 'Private',
      collectionAuthor: author_id,
      collectionTitle: title,
      collectionDescription: description,
      collectionCards: collection_models ? this.processCardsFromDtoHomeFeed(collection_models, collection_id) : []
    }));
  }

  private processDraftCollectionDtoResponse(rawResponse: DtoICollection[]): ICardCollection[] {
    let collections: ICardCollection[] = [];
    const mockedCollections: DtoICollection[] = rawResponse
    collections = mockedCollections.map(dtoCollection => {
      return {
        collectionId: dtoCollection.collection_id,
        collectionContentMobile: dtoCollection.collection_preview_link_app,
        collectionPosterMobile: dtoCollection.collection_poster_link_app,
        collectionContentWeb: dtoCollection.collection_preview_link_web,
        collectionPosterWeb: dtoCollection.collection_poster_link_web,
        accessType: 'Private',
        collectionAuthor: dtoCollection.author_id,
        collectionTitle: dtoCollection.title,
        collectionDescription: dtoCollection.description,
        collectionCards: dtoCollection.collection_models ? this.processUserCardsFromDraftCollection(dtoCollection.collection_models, dtoCollection.collection_id) : []
      }
    })
    console.log('processDraftCollectionDtoResponse Processed: ', collections)
    return collections
  }

  public processCardsFromDtoHomeFeed(cardsDto: DtoICard[], parentCollectionId: string): ICardExtended[] {
    const cards: ICard[] = cardsDto.map(dtoCard => {
      return {
        id: dtoCard.model_id,
        parentCollectionId,
        modifiedDate: dtoCard.last_update,
        cardContentMobile: dtoCard.preview_link_app,
        cardPosterMobile: dtoCard.poster_link_app,
        cardPosterWeb: dtoCard.poster_link_web,
        cardContentWeb: dtoCard.preview_link_web,
        cardCategory: dtoCard.card_category ? dtoCard.card_category : '',
        cardType: dtoCard.type === 'collection' ? CARD_TYPE.COLLECTION : CARD_TYPE.MODEL,
        cardAuthor: dtoCard.user_id,
        cardTitle: dtoCard.title,
        cardDescription: dtoCard.description,
        cardPrice: dtoCard.price ? dtoCard.price : undefined,
        purchaseStatus:dtoCard.status ? this.processCardStatus(dtoCard.status) : CARD_PURCHASE_STATUS.DEFAULT_3DWAY
      }
    })
    return cards
  }


  public processUserCardsFromDraftCollection(cardsDto: DtoICard[], parentCollectionId: string): ICardExtended[] {
    const cards: ICard[] = cardsDto.map(dtoCard => {
      let finalPurchaseStatus
      if(dtoCard.status === 'published') {
        finalPurchaseStatus = CARD_PURCHASE_STATUS.DRAFT_LIKED;
      } else {
        finalPurchaseStatus = dtoCard.review_status;
      }
      return {
        id: dtoCard.model_id,
        parentCollectionId,
        modifiedDate: dtoCard.last_update,
        cardContentMobile: dtoCard.preview_link_app  || '',
        cardPosterMobile: dtoCard.poster_link_app || '',
        cardContentWeb: dtoCard.preview_link_web || '',
        cardPosterWeb: dtoCard.poster_link_web || '',
        cardCategory: dtoCard.card_category ? dtoCard.card_category : '',
        cardType: dtoCard.type === 'collection' ? CARD_TYPE.COLLECTION : CARD_TYPE.MODEL,
        cardAuthor: dtoCard.user_id,
        cardTitle: dtoCard.title,
        cardDescription: dtoCard.description,
        cardPrice: dtoCard.price ? dtoCard.price : undefined,
        purchaseStatus: finalPurchaseStatus
      }
    })
    return cards
  }

  public processExtendedCardsFromDto(cardsDto: DtoICard[]): ICardExtended[] {
    const cards: ICardExtended[] = cardsDto.map(dtoCard => {
      return {
        id: dtoCard.model_id,
        modifiedDate: dtoCard.last_update,
        cardContentMobile: dtoCard.preview_link_app || '',
        cardPosterMobile: dtoCard.poster_link_app || '',
        cardPosterWeb: dtoCard.poster_link_web || '',
        cardContentWeb: dtoCard.preview_link_web || '',
        cardCategory: dtoCard.card_category ? dtoCard.card_category : 'N/A',
        cardType: dtoCard.type === 'collection' ? CARD_TYPE.COLLECTION : CARD_TYPE.MODEL,
        cardAuthor: dtoCard.user_id,
        cardTitle: dtoCard.title,
        cardDescription: dtoCard.description,
        cardPrice: dtoCard.price ? dtoCard.price : undefined,
        // cardPrice: Math.floor(Math.random() * (100 - 1 + 1) + 1),
        purchaseStatus:dtoCard.status ? this.processCardStatus(dtoCard.status) : CARD_PURCHASE_STATUS.DEFAULT_3DWAY,
        reviewDetails: dtoCard.review_details ? dtoCard.review_details : undefined
      }
    })
    return cards
  }

  private convertCollectionToDtoCollection(collection: ICardCollection): DtoICollection {
      return {
        collection_id: collection.collectionId,
        collection_preview_link_app: collection.collectionContentMobile,
        collection_poster_link_app: collection.collectionPosterMobile,
        collection_poster_link_web: collection.collectionPosterMobile,
        collection_preview_link_web: collection.collectionContentMobile,
        author_id: collection.collectionAuthor,
        title: collection.collectionTitle,
        description: collection.collectionDescription,
        collection_models: collection.collectionCards ? this.convertCardsToDtoCards(collection.collectionCards) : [],
        type: 'collection',
        status: 'private'
      }
  }

  private convertCardsToDtoCards(cards: ICard[]): DtoICard[] {
    const processedCards = cards.map(cardOriginal => {
      return {
        model_id: cardOriginal.id,
        last_update: this.convertDateToISO8601(cardOriginal.modifiedDate),
        public_model_id: cardOriginal.publicModelId,
        collectionId: cardOriginal.parentCollectionId,
        preview_link_app: cardOriginal.cardContentMobile,
        poster_link_app: cardOriginal.cardPosterMobile,
        poster_link_web: cardOriginal.cardPosterWeb,
        preview_link_web: cardOriginal.cardContentWeb,
        user_id: cardOriginal.cardAuthor,
        card_category: cardOriginal.cardCategory,
        card_subcategory: 'N/A',
        download_link: cardOriginal.cardModelLink,
        title: cardOriginal.cardTitle,
        description: cardOriginal.cardDescription,
        price: this.convertCardPrice(cardOriginal.cardPrice),
        type: cardOriginal.cardType === CARD_TYPE.COLLECTION ? 'collection' : 'model',
        previewType: cardOriginal.cardType,
        status: cardOriginal.purchaseStatus,
        review_status: cardOriginal.purchaseStatus
      }
    })
    return processedCards
  }


  public async processCardsFromDtoAsyncWithDownloadLink(cardsDto: DtoICard[], isReviewStatusProcessed: boolean ): Promise<ICardExtended[]> {
    const cards: ICard[] = await Promise.all(cardsDto.map(async dtoCard => {
      const cardModelLink = dtoCard.download_link ? await this.firebaseAuth.getDownloadUrlRef(dtoCard.download_link) : undefined;

      let purchaseStatusProcessed;
      if(isReviewStatusProcessed) {
        purchaseStatusProcessed = this.processCardStatus(dtoCard.status)
      } else {
        purchaseStatusProcessed = dtoCard.review_status ? this.processCardStatus(dtoCard.review_status) : CARD_PURCHASE_STATUS.UPLOADED
      }

      return {
        id: dtoCard.model_id,
        modifiedDate: dtoCard.last_update,
        publicModelId: dtoCard.public_model_id,
        cardContentMobile: dtoCard.preview_link_app || '',
        cardPosterMobile: dtoCard.poster_link_app || '',
        cardPosterWeb: dtoCard.poster_link_web || '',
        cardContentWeb: dtoCard.preview_link_web || '',
        cardModelLink,
        cardCategory: dtoCard.card_category ? dtoCard.card_category : '',
        cardType: dtoCard.type === 'collection' ? CARD_TYPE.COLLECTION : CARD_TYPE.MODEL,
        cardAuthor: dtoCard.user_id,
        cardTitle: dtoCard.title,
        cardDescription: dtoCard.description,
        cardPrice: dtoCard.price ? dtoCard.price : undefined,
        // cardPrice: Math.floor(Math.random() * (100 - 1 + 1) + 1),
        purchaseStatus: purchaseStatusProcessed
      };
    }));
    return cards;
  }

  private processCardStatus(status: string): CARD_PURCHASE_STATUS {
    switch (status) {
      case 'purchased':
        return CARD_PURCHASE_STATUS.PURCHASED
      case 'uploaded':
        return CARD_PURCHASE_STATUS.UPLOADED
      case 'liked':
        return CARD_PURCHASE_STATUS.DRAFT_LIKED
      case 'in_review':
        return CARD_PURCHASE_STATUS.IN_REVIEW
      case 'rejected':
        return CARD_PURCHASE_STATUS.REJECTED
      case 'published':
        return CARD_PURCHASE_STATUS.PUBLISHED
      case 'review_publish_reject':
        return CARD_PURCHASE_STATUS.REVIEW_PUBLISH_REJECT
      default:
        return CARD_PURCHASE_STATUS.DEFAULT_3DWAY
    }
  }

  private convertCardPrice(price: string | number | undefined): number {
    if (typeof price === 'string' && /^\d+$/.test(price)) {
      return parseInt(price, 10);
    } else if (typeof price === 'number') {
      return price;
    } else {
      return undefined
    }
  }

  private userScrolledAtLeastOnce(): void {
    this.firebaseAuth.setFirstTimeScrolledAnyFeed(true);
  }

  private convertDateToISO8601(date: string): string {
    const dateObj = new Date(date);
    return dateObj.toISOString();
  }


  downloadFile(url: string, filename: string): void {
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


}


