import { DragDropModule } from '@angular/cdk/drag-drop';
import { TrashbinComponent } from './components/trashbin/trashbin.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { MaterialModule } from './material.module';

// Components
import { ModelsBarComponent } from './components/models-bar/models-bar.component';

// Services
import { RedirectGuard } from './providers/redirect-guard';
import { AuthGuard } from './providers/auth.guard';
import { MockDataService } from './providers/mock-data.service';
import { SettingsService } from './providers/settings.service';


// Directives
import { SubmitIfValidDirective } from './directives/submit-if-valid.directive';
import { HttpClientModule } from '@angular/common/http';
import { CustomClassDirective } from './directives/custom-class.directive';
import { FluidLayoutService } from './providers/fluid-layout/fluid-layout.service';
import { LocationBreadcrumbsComponent } from './components/location-breadcrumbs/location-breadcrumbs.component';
import { ModelsCardComponent } from './components/models-card/models-card.component';
import { FeedbackCardComponent } from './components/feedback-card/feedback-card.component';
import { CardDetailsComponent } from './components/card-details/card-details.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SearchComponent } from './components/search/search.component';
import { FooterMenuComponent } from './components/footer-menu/footer-menu.component';
import { RecCardComponent } from './components/rec-card/rec-card.component';
import { MakeReconstructComponent } from './components/make-reconstruct/make-reconstruct.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CardFullscreenComponent } from './components/card-fullscreen/card-fullscreen.component';
import { CategoriesListComponent } from './components/categories-list/categories-list.component';
import { ReviewFormComponent } from './components/review-form/review-form.component';
import { IntersectionObserverDirective } from './directives/intersection-observer.directive';
import { NavpanelComponent } from './components/navpanel/navpanel.component';
import { CardsGroupedComponent } from './components/cards-grouped/cards-grouped.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { CardVideoComponent } from './components/card-video/card-video.component';
import { CollectionDetailsComponent } from './components/collection-details/collection-details.component';
import { FormatImageExtensionPipe } from './pipes/format-image-extension.pipe';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { ProfileMainComponent } from './components/profile-main/profile-main.component';
import { LibraryUploadComponent } from './components/library-upload/library-upload.component';
import { CardThumbsUploadComponent } from './components/card-thumbs-upload/card-thumbs-upload.component';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { CardHorizontalSliderComponent } from './components/card-horizontal-slider/card-horizontal-slider.component';
import { ThumbnailGalleryComponent } from './components/thumbnail-gallery/thumbnail-gallery.component';
import { CardSelectPanelComponent } from './components/card-select-panel/card-select-panel.component';
import { CreateNewCollectionComponent } from './components/create-new-collection/create-new-collection.component';
import { LibrarySectionSwitchComponent } from './components/library-section-switch/library-section-switch.component';
import { CollectionSelectPanelComponent } from './components/collection-select-panel/collection-select-panel.component';
import { PriceSelectPanelComponent } from './components/price-select-panel/price-select-panel.component';
import { WelcomeBundleBannerComponent } from './components/welcome-bundle-banner/welcome-bundle-banner.component';
import { NameChangeDialogueComponent } from './components/name-change-dialogue/name-change-dialogue.component';
import { RestoreDeletedDialogueComponent } from './components/restore-deleted-dialogue/restore-deleted-dialogue.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CallbackPipe } from './pipes/callback.pipe';
import { PriceformatPipe } from './pipes/priceformat.pipe';
import { EditCollaboratorsComponent } from './components/edit-collaborators/edit-collaborators.component';
import { CardPublishPanelComponent } from './components/card-publish-panel/card-publish-panel.component';
import { SubscriptionSplashComponent } from './components/subscription-splash/subscription-splash.component';
import { SeeAllSwitchComponent } from './components/see-all-switch/see-all-switch.component';
import { RejectedScreenComponent } from './components/rejected-screen/rejected-screen.component';
import { CardBuyPanelComponent } from './components/card-buy-panel/card-buy-panel.component';
import { EmptyCardComponent } from './components/empty-card/empty-card.component';
import { CardCompositeSliderComponent } from './components/card-composite-slider/card-composite-slider.component';
import { SortByDatePipe } from './pipes/sort-by-date.pipe';
import { StopRenderSnackBarComponent } from './stop-render-snack-bar/stop-render-snack-bar.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ThumbCompositeComponent } from './components/thumb-composite/thumb-composite.component';
import { CustomBreakPointsProvider } from './providers/custom-breakpoints.provider';
import { FlexLayoutModule, LayoutGapStyleBuilder } from '@angular/flex-layout';
import { CustomLayoutGapStyleBuilder } from './providers/custom-gaps-builder';
import { ResponsiveDirective } from './directives/responsive.directive';
import { FirstUseAdvComponent } from './components/first-use-adv/first-use-adv.component';
import { AuthpopupComponent } from './components/authpopup/authpopup.component';
import { RenderlimitSelectComponent } from './components/renderlimit-select/renderlimit-select.component';
import { MobileRenderLimitComponent } from './components/mobile-render-limit/mobile-render-limit.component';



@NgModule({
  declarations: [
    ModelsBarComponent,
    SubmitIfValidDirective,
    IntersectionObserverDirective,
    CustomClassDirective,
    ResponsiveDirective,
    LocationBreadcrumbsComponent,
    ModelsCardComponent,
    FeedbackCardComponent,
    CardDetailsComponent,
    NavbarComponent,
    SearchComponent,
    FooterMenuComponent,
    RecCardComponent,
    MakeReconstructComponent,
    TrashbinComponent,
    CategoriesComponent,
    CardFullscreenComponent,
    CategoriesListComponent,
    ReviewFormComponent,
    NavpanelComponent,
    CardsGroupedComponent,
    UnderConstructionComponent,
    CardVideoComponent,
    CollectionDetailsComponent,
    FormatImageExtensionPipe,
    CallbackPipe,
    PriceformatPipe,
    SortByDatePipe,
    SafeUrlPipe,

    SplashScreenComponent,
    ProfileMainComponent,
    LibraryUploadComponent,
    CardThumbsUploadComponent,
    UploadProgressComponent,
    CardHorizontalSliderComponent,
    ThumbnailGalleryComponent,
    CardSelectPanelComponent,
    CardBuyPanelComponent,
    CardPublishPanelComponent,
    NameChangeDialogueComponent,
    CreateNewCollectionComponent,
    LibrarySectionSwitchComponent,
    CollectionSelectPanelComponent,
    PriceSelectPanelComponent,
    WelcomeBundleBannerComponent,
    RestoreDeletedDialogueComponent,
    EditCollaboratorsComponent,
    SubscriptionSplashComponent,
    SeeAllSwitchComponent,
    RejectedScreenComponent,
    EmptyCardComponent,
    CardCompositeSliderComponent,
    StopRenderSnackBarComponent,
    ThumbCompositeComponent,
    FirstUseAdvComponent,
    AuthpopupComponent,
    RenderlimitSelectComponent,
    MobileRenderLimitComponent,
  ],
  exports: [
    // Components
    ModelsBarComponent,
    SubmitIfValidDirective,
    IntersectionObserverDirective,
    CustomClassDirective,
    ResponsiveDirective,
    LocationBreadcrumbsComponent,
    ModelsCardComponent,
    FeedbackCardComponent,
    CardDetailsComponent,
    NavbarComponent,
    SearchComponent,
    FooterMenuComponent,
    RecCardComponent,
    MakeReconstructComponent,
    TrashbinComponent,
    CategoriesComponent,
    CardFullscreenComponent,
    CategoriesListComponent,
    ReviewFormComponent,
    NavpanelComponent,
    CardsGroupedComponent,
    UnderConstructionComponent,
    CardVideoComponent,
    CollectionDetailsComponent,
    SplashScreenComponent,
    ProfileMainComponent,
    LibraryUploadComponent,
    CardThumbsUploadComponent,
    UploadProgressComponent,
    CardHorizontalSliderComponent,
    ThumbnailGalleryComponent,
    CardSelectPanelComponent,
    CardBuyPanelComponent,
    CardPublishPanelComponent,
    NameChangeDialogueComponent,
    CreateNewCollectionComponent,
    LibrarySectionSwitchComponent,
    CollectionSelectPanelComponent,
    PriceSelectPanelComponent,
    WelcomeBundleBannerComponent,
    RestoreDeletedDialogueComponent,
    EditCollaboratorsComponent,
    SubscriptionSplashComponent,
    SeeAllSwitchComponent,
    RejectedScreenComponent,
    EmptyCardComponent,
    CardCompositeSliderComponent,
    StopRenderSnackBarComponent,
    ThumbCompositeComponent,
    FirstUseAdvComponent,
    RenderlimitSelectComponent,
    MobileRenderLimitComponent,

    FormatImageExtensionPipe,
    CallbackPipe,
    PriceformatPipe,
    SortByDatePipe,
    SafeUrlPipe,

    // Modules
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    DragDropModule,
    ScrollingModule,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    DragDropModule,
    ScrollingModule,

  ],
  providers: [
    RedirectGuard,
    AuthGuard,
    FluidLayoutService,
    MockDataService,
    SettingsService,
    CustomBreakPointsProvider,
    { provide: LayoutGapStyleBuilder, useClass: CustomLayoutGapStyleBuilder }
  ],
})
export class SharedModule { }
