import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Network, ConnectionStatus } from '@capacitor/network';
import { Device } from '@capacitor/device';
import { Toast } from '@capacitor/toast';
import { SplashScreen } from '@capacitor/splash-screen';
import { LOCALSTORAGE_VAR } from 'src/app/models/localstorage';
import { LOG_LEVEL_OPTION } from 'src/app/models/common';
import { GradientService } from './gradient.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public devicePlatform: string = 'web';

  public skeletonChunkLength: number = 6;
  // public scrollPowerDeccelerationThreshold: number = 500;
  public skeletonChunkLoadDelay: number = 1200;
  public isChunkLoaderShown: boolean = false;
  public cardRenderBufferBackward: number = 1;
  public cardRenderBuffer: number = 2;

  public originalUid: string;
  public customUid: string;

  public animationRatio: number = 0.8;

  public posterBufferForward: number = 6;
  public posterBufferBackward: number = 2;

  // var(--progress-bar-timing)
  public cardStartDelayTime: number = 1800;
  public progressBarHideDelay: number = 1000;

  public wrapperAnimationTime: number = 1150;
  public wrapperAnimationDelay: number = 650;
  public titleAnimationTime: number = 500;
  public titleAnimationDelay: number = 650;
  public descriptionAnimationTime: number =850;
  public descriptionAnimationDelay: number = 950;

  // snackBarAnimation
  public snackBarAppearTiming: number = 350;
  public snackBarHideTiming: number = 200;
  public snackBarAppearScale: number = 0.6;

  public ignoreRenderLimitCheck: boolean = false;


  // Style settings for gradient;

  public showStyleTweaker = false;
  public colors: { base: string; alpha: number }[] = [
    { base: '#000000', alpha: 1 }, // Default to white, fully opaque
    { base: '#000000', alpha: 0 }, // Default to black, fully opaque
  ];
  bezierPoints = [0.48, 0.3, 0.64, 1];
  gradientData = '';
  customPadding = 10;
  customHeight = 100;

  applyTo: string = 'model-feed'; // Default value


  // library section switch

  public showDateModified: boolean = false;

  public showDetailedLogForModelCards: boolean = false;

  public get logLevelSelector() {
    return this.getLogLevelFromLocalstorage();
  }
  public set logLevelSelector(source: string) {
    this.setLogLevelFromLocalstorage(source);
  }

  public get frameNumberToRender() {
    return this.getFrameNumberFromLocalstorage().toString();
  }
  public set frameNumberToRender(source: string) {
    this.setFrameNumberFromLocalstorage(source);
  }

  public get additionalSettingsEnabled() {
    return this.getAdditionalSettingsEnabled();
  }

  public set additionalSettingsEnabled(source: boolean) {
    this.setAdditionalSettingsEnabled(source);
  }

  public backBtnEventDelayTime: number = 50;
  public discoverBtnEventDelayTime: number = 50;
  public homeBtnEventDelayTime: number = 50;

  public collectionDetailsScrollSpeed: number = 2500;



  public isSplashHidden: boolean = false;
  public videoResizeType: string = 'cover';

  public showServicePageAndShowDebugDataDataOnCard: boolean;
  public networkStatus: ConnectionStatus = {
    connected: true,
    connectionType: 'unknown'
  }

  constructor(
    public gradientService: GradientService
  ) {


    this.customUid = localStorage.getItem(LOCALSTORAGE_VAR.CUSTOM_UID);
    // DISABLE CLEAR CUSTOM UID;
    this.clearCustomUidInLocalStorage();

    Device.getInfo().then((deviceInfo) => {
      this.devicePlatform = deviceInfo.platform;
    });

    Network.getStatus().then((firstStatus) => {
      this.networkStatus = firstStatus
      console.log('*NETWORK STATUS*: ', this.networkStatus)
    });

    Network.addListener('networkStatusChange', changedStatus => {
      console.log('Network status changed', changedStatus);
      Network.getStatus().then((status) => {
        this.networkStatus = status
        console.log('*NETWORK STATUS*: ', this.networkStatus)
      });
    });

    // this.showServicePageAndShowDebugDataDataOnCard = environment.showServicePageAndShowDebugData ? true : false;
    this.showServicePageAndShowDebugDataDataOnCard = false;

    const collectionDetailsGrad = 'linear-gradient(to top, hsl(0, 0%, 0%), hsla(0, 0%, 0%, 0.93933) 9.09%, hsla(0, 0%, 0%, 0.8709) 17.63%, hsla(0, 0%, 0%, 0.79631) 25.65%, hsla(0, 0%, 0%, 0.71719) 33.2%, hsla(0, 0%, 0%, 0.63513) 40.31%, hsla(0, 0%, 0%, 0.55176) 47.02%, hsla(0, 0%, 0%, 0.46868) 53.36%, hsla(0, 0%, 0%, 0.3875) 59.38%, hsla(0, 0%, 0%, 0.30984) 65.09%, hsla(0, 0%, 0%, 0.2373) 70.56%, hsla(0, 0%, 0%, 0.17151) 75.8%, hsla(0, 0%, 0%, 0.11406) 80.86%, hsla(0, 0%, 0%, 0.06658) 85.77%, hsla(0, 0%, 0%, 0.03066) 90.58%, hsla(0, 0%, 0%, 0.00793) 95.31%, hsla(0, 0%, 0%, 0))'

    const modelTopGradientData = 'linear-gradient(to top, hsla(0, 0%, 0%, 0.85), hsla(0, 0%, 0%, 0.79843) 9.09%, hsla(0, 0%, 0%, 0.74026) 17.63%, hsla(0, 0%, 0%, 0.67687) 25.65%, hsla(0, 0%, 0%, 0.60961) 33.2%, hsla(0, 0%, 0%, 0.53986) 40.31%, hsla(0, 0%, 0%, 0.46899) 47.02%, hsla(0, 0%, 0%, 0.39838) 53.36%, hsla(0, 0%, 0%, 0.32937) 59.38%, hsla(0, 0%, 0%, 0.26336) 65.09%, hsla(0, 0%, 0%, 0.20171) 70.56%, hsla(0, 0%, 0%, 0.14578) 75.8%, hsla(0, 0%, 0%, 0.09695) 80.86%, hsla(0, 0%, 0%, 0.05659) 85.77%, hsla(0, 0%, 0%, 0.02606) 90.58%, hsla(0, 0%, 0%, 0.00674) 95.31%, hsla(0, 0%, 0%, 0))'

    window.document.documentElement.style.setProperty('--customgradientfeed', modelTopGradientData);
    window.document.documentElement.style.setProperty('--customtoppadding', this.customPadding + 'px');
    window.document.documentElement.style.setProperty('--customgradientdetails', collectionDetailsGrad);
    window.document.documentElement.style.setProperty('--customheight', 100 + '%');

   }

   showToastMsg(text): void {
    Toast.show({
      text , duration: 'long', position: 'top'
    });
   }

   hideSplashScreen(): void {
    if(this.isSplashHidden === false ) {
      this.isSplashHidden = true;
      SplashScreen.hide({fadeOutDuration: 350});
      console.log('PERF_ Splash hidden:', performance.now())
    }
   }

   setCustomUidInLocalstorage(value: string): void {
    localStorage.setItem(LOCALSTORAGE_VAR.CUSTOM_UID, value.toString());
   }

   getCustomUidInLocalStorage(): string {
    const customUid = localStorage.getItem(LOCALSTORAGE_VAR.CUSTOM_UID);
    return customUid ? customUid : '';
   }

   clearCustomUidInLocalStorage(): void {
    localStorage.removeItem(LOCALSTORAGE_VAR.CUSTOM_UID);
   }

  setLogLevelFromLocalstorage(source: string) {
    localStorage.setItem(LOCALSTORAGE_VAR.LOG_LEVEL, source.toString());
  }
  getLogLevelFromLocalstorage(): string {
    const logLevel = localStorage.getItem(LOCALSTORAGE_VAR.LOG_LEVEL);
    return logLevel ? logLevel : LOG_LEVEL_OPTION.PROD_MODE;
  }

  getOriginalUidFromLocalstorage(): string {
    return localStorage.getItem(LOCALSTORAGE_VAR.ORIGINAL_UID);
   }


  setFrameNumberFromLocalstorage(frameNumber: string) {
    localStorage.setItem(LOCALSTORAGE_VAR.FRAME_NUMBER, frameNumber);
  }
  getFrameNumberFromLocalstorage(): number {
    const rawFrameNumber = localStorage.getItem(LOCALSTORAGE_VAR.FRAME_NUMBER);
    let processedFrameNumber
    if(rawFrameNumber) {
      processedFrameNumber = parseInt(rawFrameNumber, 10);
    }
    return processedFrameNumber ? processedFrameNumber : 900;
  }

  setAdditionalSettingsEnabled(value: boolean): void {
    localStorage.setItem(LOCALSTORAGE_VAR.ADDITIONAL_SETTINGS_ENABLED, value.toString());
  }

  getAdditionalSettingsEnabled(): boolean {
    const additionalSettingsEnabled = localStorage.getItem(LOCALSTORAGE_VAR.ADDITIONAL_SETTINGS_ENABLED);
    return additionalSettingsEnabled && additionalSettingsEnabled === 'true' ? true : false
  }


  // STYLE SETTINGS FOR GRADIENT

  updateStyles() {
    console.log(this.bezierPoints);
    const coordinates = this.calculateBezierCoordinates(this.bezierPoints, 16);  // Generate 16 coordinates

    // Convert colors with transparency to RGBA strings
    const rgbaColors = this.colors.map(color => {
      const rgb = this.hexToRgb(color.base);
      return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${color.alpha})`;
    });

    const gradient = this.gradientService.generateColorStops(rgbaColors, coordinates, 5, 'hsl');
    this.gradientData = `linear-gradient(to top, ${gradient.join(', ')})`;
    this.applyGradient();
  }

  // Assuming the hexToRgb function is available in the service
  // And this.colors is an array of { base: string, alpha: number }


  applyGradient() {
    // Make sure this.gradientData is formatted correctly

    // Set the --customgradientfeed variable on the root element
    if (this.applyTo === 'model-feed') {
      window.document.documentElement.style.setProperty('--customgradientfeed', this.gradientData);
      window.document.documentElement.style.setProperty('--customtoppadding', this.customPadding + 'px');
    }
    if(this.applyTo === 'collection-details') {
      window.document.documentElement.style.setProperty('--customgradientdetails', this.gradientData);
      window.document.documentElement.style.setProperty('--customheight', this.customHeight + '%');

    }

    console.log('Applied gradient:', this.gradientData);
  }

  getRgbaString(color: { base: string; alpha: number }): string {
    const rgb = this.hexToRgb(color.base); // You'll need to convert hex color to rgb
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${color.alpha})`;
  }

  // Utility function to convert hex to RGB
  public hexToRgb(hex: string) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }


  public calculateBezierCoordinates(points: number[], steps: number): { x: number; y: number }[] {
    const coordinates = [];

    // Extract control points
    const [p0, p1, p2, p3] = [
      { x: 0, y: 0 }, // Start point is always (0, 0) for CSS transitions
      { x: points[0], y: points[1] }, // First control point
      { x: points[2], y: points[3] }, // Second control point
      { x: 1, y: 1 }  // End point is always (1, 1) for CSS transitions
    ];

    for (let i = 0; i <= steps; i++) {
      const t = i / steps;
      const x = this.computeBezier(t, p0.x, p1.x, p2.x, p3.x);
      const y = this.computeBezier(t, p0.y, p1.y, p2.y, p3.y);
      coordinates.push({ x, y });
    }

    return coordinates;
  }

  private computeBezier(t: number, p0: number, p1: number, p2: number, p3: number): number {
    return Math.pow(1 - t, 3) * p0 +
           3 * Math.pow(1 - t, 2) * t * p1 +
           3 * (1 - t) * Math.pow(t, 2) * p2 +
           Math.pow(t, 3) * p3;
  }

}
