<app-navbar  [footerBarMode]="currentFooterMode" ></app-navbar>

<div class="service-settings-page">

  <mat-tab-group>
    <mat-tab label="Advanced">
      <div *ngIf="error">
        <label style="color: red">{{error}}</label>
      </div>
      <br><br>
      <mat-checkbox [(ngModel)]="settingsService.ignoreRenderLimitCheck" ><strong>Ignore render limit check on frontend</strong></mat-checkbox>
      <br><br>
      <mat-checkbox [(ngModel)]="settingsService.showStyleTweaker" ><strong>Show style tweaker for store</strong></mat-checkbox>
      <section class="example-section">
        <label class="example-margin">Notif. Level:</label>
        <mat-radio-group [(ngModel)]="settingsService.logLevelSelector">
          <mat-radio-button class="example-margin" [value]="LOG_LEVEL_OPTION.DEFAULT">
            Default
          </mat-radio-button>
          <mat-radio-button class="example-margin" [value]="LOG_LEVEL_OPTION.ERROR_ONLY">
            ErrorsOnly
          </mat-radio-button>
          <mat-radio-button class="example-margin" [value]="LOG_LEVEL_OPTION.PROD_MODE">
            Prod
          </mat-radio-button>
        </mat-radio-group>
        <br><br>
        <br><br>
        <label class="example-margin">Frames to render </label>
        <input type="number" class="blacked-input" [placeholder]="900" [(ngModel)]="settingsService.frameNumberToRender" >
        <br>
        <br>
        <mat-checkbox [(ngModel)]="settingsService.showDateModified" ><strong>Show modified date on thumbs</strong></mat-checkbox>
        <br>
        <br>
        <mat-checkbox [(ngModel)]="settingsService.additionalSettingsEnabled" ><strong>Additional Settings Enabled</strong></mat-checkbox>
        <br>
        <br>
        <hr>
          <br>
          <label class="example-margin">DONT FORGET TO RELAUNCH APP AFTER CHANGING FEED SOURCE!</label>
          <br>
          <br>

          <section class="example-section">

            Your User UID (Original): <input type="string" style="width: 250px;" class="blacked-input-large" readonly value="{{originalUid}}" ><br>
            Your User UID (Current): {{customUid}} <br><br>
            <label class="example-margin">Set custom UID: </label>
            <input type="string" class="blacked-input-large" [placeholder]="" [(ngModel)]="newCustomUid" >
            <br><br>
            <button mat-flat-button color="default" (click)="applyUid()">Apply custom UID</button>
            <br><br>
          </section>
        <hr>
        <br>
        <br>
        <button mat-flat-button color="default" (click)="clearUploads()">Clear upload models</button>
        <br>
        <br>
        <br>
        <button mat-flat-button color="default" (click)="restorePurchases()">restorePurchases</button>
        <br>
        <br>
        <button mat-flat-button color="default" (click)="manageSubscriptions()">manageSubscriptions</button>
        <br>
        <br>
        <button mat-flat-button color="default" (click)="manageBilling()">manageBilling</button>
        <br>
        <br>
        <h5> <strong> Log for finishing products: </strong>  </h5>
        <pre *ngFor="let product of approvedProdcuts">
           {{product | json}}
        </pre>
        <br>

        <!-- <label class="example-margin">Progress bar hide delay: </label>
        <input type="number" class="blacked-input" [placeholder]="1000" [(ngModel)]="settingsService.progressBarHideDelay" >
        <br> -->
        <!-- <section class="example-section">
          <label class="example-margin">Delay lable type:</label>
          <mat-radio-group [(ngModel)]="settingsService.delayProgressType">
            <mat-radio-button class="example-margin" value="progressBar">
              Circle
            </mat-radio-button>
            <mat-radio-button class="example-margin" value="autoplayLabel">
              AutoPlay
            </mat-radio-button>
            <mat-radio-button class="example-margin" value="spinner">
              Spinner
            </mat-radio-button>
            <mat-radio-button class="example-margin" value="none">
              None
            </mat-radio-button>
          </mat-radio-group>
        </section> -->



        <button mat-flat-button color="default" (click)="getToken()">Get Auth Token</button>
        <br>
        <br>
        <label class="example-margin">DOM-Skeleton Chunk Length: </label>
        <input type="number" class="blacked-input" [placeholder]="2" [(ngModel)]="settingsService.skeletonChunkLength" >
        <br>
        <br>
        <label class="example-margin">Card render buffer(Should be even number): </label>
        <input type="number" class="blacked-input" [placeholder]="4" [(ngModel)]="settingsService.cardRenderBuffer" >

      </section>
      <div class="checkbox-wrapper ">
      </div>

      <br>
      <button mat-flat-button color="error" (click)="openDeleteCardConfirmationDialog()">delete all models</button>
      <br>

      <br>
      <pre>
        {{ cardService.processCardStats() | json}}
      </pre>
      <br>
    </mat-tab>
    <mat-tab label="Visuals">

      <div class="checkbox-wrapper margin-top-10">
        <label class="example-margin">Video resize type:</label>
        <mat-radio-group [(ngModel)]="settingsService.videoResizeType">
          <mat-radio-button class="example-margin" value="cover">
            Cover
          </mat-radio-button>
          <mat-radio-button class="example-margin" value="fill">
            Fill
          </mat-radio-button>
          <mat-radio-button class="example-margin" value="contain">
            Contain
          </mat-radio-button>
        </mat-radio-group>
    </div>

      <br>
      <label class="example-margin">Snackbar appear timing: </label>
      <input type="number" class="blacked-input" [placeholder]="350" [(ngModel)]="settingsService.snackBarAppearTiming" >
      <br>
      <br>
      <label class="example-margin">Snackbar hide timing: </label>
      <input type="number" class="blacked-input" [placeholder]="200" [(ngModel)]="settingsService.snackBarHideTiming" >
      <br>
      <br>
      <label class="example-margin">Snackbar appear start scale: </label>
      <input type="number" class="blacked-input" [placeholder]="0.6" [(ngModel)]="settingsService.snackBarAppearScale" >
      <br>
      <br>
      <button mat-flat-button color="default" (click)="patchSnackBarAnimation()">Apply snack animation changes</button>
      <br>
      <br>

      <br>
      <label class="example-margin">Grad 'fadein' time: </label>
      <input type="number" class="blacked-input" [placeholder]="400" [(ngModel)]="settingsService.wrapperAnimationTime" >
      <label class="example-margin margin-left">Grad 'fadein' delay: </label>
      <input type="number" class="blacked-input"  [placeholder]="250" [(ngModel)]="settingsService.wrapperAnimationDelay" >
      <br>
      <label class="example-margin">Title 'fadein' time: </label>
      <input type="number" class="blacked-input" [placeholder]="450" [(ngModel)]="settingsService.titleAnimationTime" >
      <label class="example-margin margin-left">Title 'fadein' delay: </label>
      <input type="number" class="blacked-input" [placeholder]="300" [(ngModel)]="settingsService.titleAnimationDelay" >
      <br>
      <label class="example-margin">Desc 'fadein' time: </label>
      <input type="number" class="blacked-input" [placeholder]="300" [(ngModel)]="settingsService.descriptionAnimationTime" >
      <label class="example-margin margin-left">Desc 'fadein' delay: </label>
      <input type="number" class="blacked-input" [placeholder]="950" [(ngModel)]="settingsService.descriptionAnimationDelay" >
      <br>
      <br>


      <br>
      <label class="example-margin">Collection details scroll speed: </label>
      <input type="number" class="blacked-input" [placeholder]="500" [(ngModel)]="settingsService.collectionDetailsScrollSpeed" >
      <br>
      <br>
      <label class="example-margin">Discover btn event trigger delay: </label>
      <input type="number" class="blacked-input" [placeholder]="50" [(ngModel)]="settingsService.discoverBtnEventDelayTime" >
      <br>
      <br>
      <label class="example-margin">Back btn event trigger delay: </label>
      <input type="number" class="blacked-input" [placeholder]="50" [(ngModel)]="settingsService.backBtnEventDelayTime" >
      <br>
      <br>
      <label class="example-margin">Home btn event trigger delay: </label>
      <input type="number" class="blacked-input" [placeholder]="50" [(ngModel)]="settingsService.homeBtnEventDelayTime" >
      <br>
      <br>
      <label class="example-margin">Card start delay: </label>
      <input type="number" class="blacked-input" [placeholder]="2000" [(ngModel)]="settingsService.cardStartDelayTime" >
      <br>


    </mat-tab>

  </mat-tab-group>





</div>

