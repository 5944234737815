import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';
import { CardsService } from '../../providers/cards.service';
import { SettingsService } from '../../providers/settings.service';
import { UploadFileService } from '../../providers/upload-file.service';
import { UserDataService } from '../../providers/user-data.service';

@Component({
  selector: 'app-mobile-render-limit',
  templateUrl: './mobile-render-limit.component.html',
  styleUrls: ['./mobile-render-limit.component.scss']
})
export class MobileRenderLimitComponent implements OnInit, OnDestroy {
  @Output() renderLimitChange = new EventEmitter<number>();

  FOOTER_MODE = FOOTER_MODE;
  renderedModelsAmount: number;

  constructor(
    public settingService: SettingsService, public cardService: CardsService, public uploadService: UploadFileService, public firebaseAuth: FirebaseAuthService
    ) {
    }

  ngOnInit(): void {
    this.renderedModelsAmount = this.firebaseAuth.currentUser.renderModelsPerCycle + this.uploadService.currentNumberOfUploadingOrRenderingModels

  }



  public onFootermodeChanged(value: FOOTER_MODE): void {

  }

  onRenderLimitChange(renderAmount: number): void {
    this.renderLimitChange.emit(renderAmount);
  }

  ngOnDestroy(): void {
  }

}
