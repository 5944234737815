<div class="profilte-top-wrapper" fxLayout="row" fxLayoutAlign="space-between center" >
  <!-- <h1 class="top-title">Your Account</h1> -->
  <h1 mat-dialog-title class="top-title" (click)="betaSettings()" [attr.title]="!environment.production ? 'Click to navigate to Beta Settings' : 'Your account'" [ngClass]="{'clickable' : !environment.production}" >Your Account</h1>
</div>
<div class="profile-wrapper">
  <ng-container #subscriptionRegion  >
    <div class="profile-section" fxLayout="column" >
      <div class="profile-section-bottom-part" fxFlex="1 1 100" fxLayout="column" fxLayoutAlign="center center">
        <div class="top-level-zone" fxFlex="1 1 100" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="left-info">
            <h3>{{firebaseAuth.currentUser?.renderModelsPerCycle}}</h3>
            <p>uploads during current billing period</p>
          </div>
          <div class="right-price">
            <h4>$4.99</h4><p class="small">per upload</p>
          </div>
        </div>

        <button class="profile-btn disabledButClickable" (click)="onManageSubscriptionClicked()">
          <span>Manage Subscription</span>
        </button>

        <!-- <p class="subscription-end-label" *ngIf="isSubscriptionEndLabelShown()">
          If you cancel now, you can still access your subscription until <br> {{ getFormattedEndDate() }}
        </p>
        <p class="subscription-end-label" *ngIf="firebaseAuth.currentUser?.subscriptionEndDateTime && firebaseAuth.currentUser?.isAutoRenewDisabled">
          <span class="red-label">You have cancelled your subscription.</span> <br>
          Your subscription ends on {{ getFormattedEndDate() }}
        </p> -->
      </div>
    </div>
  </ng-container>

  <div class="region-head" *ngIf="userDataService.isUserSubscribed" fxLayout="row" (click)="toggleRegion($event, PROFILE_REGION.RENDER_LIMIT)" fxLayoutAlign="space-between stretch" >
    <h2 class="region-title" (click)="toggleRegion($event, PROFILE_REGION.RENDER_LIMIT)">Billing Protection</h2>
    <svg *ngIf="!isRenderLimitRegionShown" class="arrow-right"  width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.RENDER_LIMIT)">
      <path d="M0.75 0.75L5.75 7L0.75 13.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)"/>
    </svg>
    <svg *ngIf="isRenderLimitRegionShown" class="arrow-down"  width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.RENDER_LIMIT)" >
      <path d="M13.25 0.75L7 5.75L0.75 0.75" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" />
    </svg>
  </div>

  <ng-container  #renderLimitRegion *ngIf="this.isRenderLimitRegionShown && userDataService.isUserSubscribed"  >
    <div class="profile-section" fxLayout="column" [@slideToggle]="isRenderLimitRegionShown">
      <div class="profile-section-bottom-part" fxFlex="1 1 100" fxLayout="row" fxLayoutAlign="center center">
        <app-renderlimit-select [inputMaxElementsInRow]="4" (renderLimitChange)="onRenderLimitChanged($event)"></app-renderlimit-select>
      </div>
    </div>
  </ng-container>

  <div class="profile-region" >
    <div class="region-head" fxLayout="row" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" fxLayoutAlign="space-between stretch" >
      <h2 class="region-title" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)">Account Management</h2>
      <svg *ngIf="!isAccountRegionShown" class="arrow-right"  width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)">
        <path d="M0.75 0.75L5.75 7L0.75 13.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)"/>
      </svg>
      <svg *ngIf="isAccountRegionShown" class="arrow-down"  width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" >
        <path d="M13.25 0.75L7 5.75L0.75 0.75" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" />
      </svg>

    </div>
    <ng-container #profileRegion *ngIf="this.isAccountRegionShown" >
        <div class="profile-section" fxLayout="column" [@slideToggle]="isAccountRegionShown">
          <div class="bottom-part" fxFlex="1 1 100" fxLayout="row" fxLayoutAlign="center center">
            <!-- <div class="section-delete-acc" (click)="deleteAccClick()"><span> Close my account and delete my data</span></div> -->
            <button class="profile-btn" (click)="deleteAccClick()">
              <span> Close my account and delete my data</span>
            </button>
          </div>
        </div>
    </ng-container>

  <div class="region-head" fxLayout="row" (click)="signOut()" fxLayoutAlign="space-between center" >
    <h2 class="region-title" (click)="signOut()">Sign Out</h2>
  </div>

  </div>



</div>
<p class="terms-text">
  <u><a href="https://www.3dway.io/legal/terms-of-service">Terms of Service</a></u> and <u><a href="https://www.3dway.io/legal/privacy-policy">Privacy Policy</a></u>.
 </p>



