import { CardsService } from 'src/app/shared/providers/cards.service';
import { Injectable } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IGroupedCard, UPLOAD_STATUS } from 'src/app/models/groupedCard';
import { FirebaseModelDto, SUBSCRIPTION_STATUS } from 'src/app/models/card';
import { FirebaseAuthService } from '../shared/providers/firebase-auth.service';
import { UtilityFunctionsService } from '../shared/providers/utility-functions.service';
import { ALLOWED_MODELS_EXTENSIONS } from '../models/common';
import { SettingsService } from '../shared/providers/settings.service';
import { UploadFileService } from '../shared/providers/upload-file.service';
import { UploadMetadata, UploadTask, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { WebNavService } from './webnavigation.service';
import { environment } from 'src/environments/environment';


// IMPORTANT: THIS SERVICE HANDLES ONLY UPLOAD FROM WEB AND ONLY PART WITH UPLOADING TO FIREBASE, NOT RENDERING ITSELF
// HANDLING/TRACKING RENDERING HAPPENS IN UPLOAD-FILE-SERVICE - MODELS PICKUP BY THIS SERVICE IN SUBSCRIPTION;

@Injectable({
  providedIn: 'root'
})
export class WebUploadService {

  public uploadingModels: IGroupedCard[] = [];

  private uploadTasks: { [uniquePath: string]: { progress: number; task: UploadTask; latestProgress: number; fileSize: number }[] } = {};
  public totalUploadProgress: number = 0;
  // public uploadTaskNotifier = new Subject<{ uniquePath: string, totalProgress?: number }>();
  public uploadGroupedCards: IGroupedCard[] = [];

  public uploadTaskNotifier = new Subject<{groupedCard: IGroupedCard} >();
  public removeUploadTaskNotifier = new Subject<string>();
  public totalUploadProgressNotifier = new Subject<number>();
  public uploadSubscriptionRequestNotifier = new Subject<string>();

  public latestUploadedModelSubscription: Subject<IGroupedCard> = new Subject();

  public files: File[] = [];

  public internalUploadProcessStarted: Subject<boolean> = new Subject();


  private filesUploadedCount = 0;
  public statusMessageSource = new BehaviorSubject<string>(''); // BehaviorSubject for the status message
  public preProcessModelNumber: number;

  constructor(
    public authService: FirebaseAuthService,
    public utitlityService: UtilityFunctionsService,
    public uploadService: UploadFileService,
    public cardService: CardsService,
    public webNavService: WebNavService,
    public firebaseAuth: FirebaseAuthService,
    public settingService: SettingsService
  ) {

  }


  checkAndRemoveCardByPreviewImage(cardPosterMobile: string) {
    this.uploadingModels = this.uploadingModels.filter(c => c.previewImageMobile !== cardPosterMobile);
  }


  private uploadFile(filesource: File, path: string, uniquePath: string, filesLength: number, collectionToUploadId?: string): void {

    // MOVE FILE FOR UPLOADING TO MAIN SCENARIO
    if(this.checkIfRootFileForProcessing(filesource)) {
      this.uploadService.webUploadToFirebase(filesource, path, collectionToUploadId);
      this.internalUploadProcessStarted.next(true);
      return;
    }

    this.files = [...this.files, filesource];
    const storage = getStorage();
    const storageRef = ref(storage, `uploaded_models/${this.authService.currentUser.uid}/${path}`);

    const meta: UploadMetadata = {
      customMetadata: {
        framesToRender: this.settingService.frameNumberToRender,
        parentCollectionId: collectionToUploadId
      }
    };

    const uploadTask = uploadBytesResumable(storageRef, filesource, meta);

    // Tracking upload tasks
    if (!this.uploadTasks[uniquePath]) {
      this.uploadTasks[uniquePath] = [];
    }

    const newGroupedCard = this.utitlityService.generateMockedUploadItem(uniquePath, uploadTask);
    this.uploadGroupedCards.push(newGroupedCard);

    const taskInfo = { progress: 0, task: uploadTask, latestProgress: 0, fileSize: filesource.size };
    this.uploadTasks[uniquePath].push(taskInfo);

    uploadTask.on('state_changed',
      (snapshot) => {
        const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        taskInfo.latestProgress = uploadProgress;
        this.updateTotalProgress();
      },
      (error) => {
        console.error(error);
      },
      () => {
        this.fileUploadSuccess();
      }
    );
  }

  private checkIfRootFileForProcessing(file: File): boolean {
    const extension = file.name.split('.').pop();
    return ALLOWED_MODELS_EXTENSIONS.includes(`.${extension}`);
  }


  public getIsNoUploadInProgress(): boolean {
    const isAnyAdditionalFilesUploading = this.totalUploadProgress > 0;
    const isAnyCardsUploadingNow = this.uploadService.uploadModels.filter((groupedCard) => {return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS})?.length > 0;
    return !isAnyAdditionalFilesUploading && !isAnyCardsUploadingNow;
  }


  public uploadFilesWithPreProcessing(files: NgxFileDropEntry[], collectionId?: string): void {
    // THIS IS FIRST POINT WHERE FILES SEND AFTER DROPPING TO DRANG N DROP ZONE
    console.log('uploadFilesWithPreProcessing triggered for ', this.authService.currentUser.subscriptionStatus);
    console.log('uploadFilesWithPreProcessing triggered for ', files);

    if (this.totalUploadProgress === 100) {
      this.resetTotalProgress();
    }

    const filteredFiles = files.filter(file => {
      const extension = file.fileEntry.name.split('.').pop();
      return ALLOWED_MODELS_EXTENSIONS.includes(`.${extension}`);
    });

    // CHECKS FOR AUTH USERS:

    if(!this.basicCheckIfUserCanUpload()) return;


    // CHECK FOR TRIAL USERS

    if (filteredFiles.length > 1 && this.authService.currentUser.subscriptionStatus !== SUBSCRIPTION_STATUS.SUBSCRIBER) {
      this.utitlityService.showImportantSnackBar(`Please upload only one file at a time, detected ${filteredFiles.length} files`, 500);
      return;
    }

    // CHECK FOR ALL USERS
    if (filteredFiles.length === 0) {
      this.utitlityService.showImportantSnackBar('Please upload only supported files: no files for supported render formats detected; ', 500);
      return;
    }

    if(this.authService.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBER && (this.firebaseAuth.currentUser.renderModelsPerCycle + filteredFiles.length + this.uploadService.currentNumberOfUploadingOrRenderingModels) > this.firebaseAuth.currentUser.renderLimit) {
    // *** CREATE HERE LOGIC TO invoke openRenderLimitDialog() from webnav.component.ts;
    // IN CASE OF 'OK' - continue, esle - return;

    // for testing purposes
     if(!environment.production && this.settingService.ignoreRenderLimitCheck) {
      this.processAndSendToUpload(files, collectionId);
      return;
     }

    const requiredMinimumRenderLimitValue = this.firebaseAuth.currentUser.renderModelsPerCycle + filteredFiles.length;
    this.webNavService.openRenderLimitDialog(requiredMinimumRenderLimitValue).then((confirmed) => {
      if (confirmed) {
        // proceed with upload
        this.processAndSendToUpload(files, collectionId);
      } else {
        console.log('Upload canceled by user');
        return;
      }
    });

    } else {
      this.processAndSendToUpload(files, collectionId);
    }

  }

  private processAndSendToUpload(files: NgxFileDropEntry[], collectionId?: string): void {
    const uniquePath = this.generateUniquePath();


    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.uploadFile(file, this.utitlityService.modifyFileNameAndPath(droppedFile.relativePath, uniquePath), uniquePath, files.length, collectionId);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public basicCheckIfUserCanUpload(): boolean {
    if (!this.authService.currentUser) {
      this.utitlityService.showImportantSnackBar('Please login to upload files', 500);
      return;
    }

    if(this.authService.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.TRIAL && this.authService.currentUser.uploadsNumber >= this.authService.currentUser.uploadNumberLimit) {
      // this.utitlityService.showImportantSnackBar('Your trial expired, please subscribe using mobile app', 500);
      this.uploadSubscriptionRequestNotifier.next('TRIAL_EXPIRED');
      return;
    }

    if(this.authService.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.TRIAL_EXPIRED) {
      // this.utitlityService.showImportantSnackBar('Your trial expired, please subscribe using mobile app', 500);
      this.uploadSubscriptionRequestNotifier.next('TRIAL_EXPIRED');
      return;
    }

    if(this.authService.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED) {
      this.uploadSubscriptionRequestNotifier.next('TRIAL_EXPIRED');
      // this.utitlityService.showImportantSnackBar('Your subscription expired, please subscribe using mobile app', 500);
      return;
    }

    return true;
  }

  private generateUniquePath(): string {
    // Get the current timestamp
    const timestamp = new Date().getTime();
    // Generate a random string (you can customize this part as needed)
    const randomString = Math.random().toString(36).substring(2, 15);
    // Combine the timestamp, a separator (like an underscore), and the random string
    const uniquePath = `${timestamp}_${randomString}`;

    return uniquePath;
  }

  private updateTotalProgress() {
    let totalProgress = 0;
    let totalSize = 0;

    // Суммирование прогресса всех загрузок и общего размера файлов
    Object.values(this.uploadTasks).forEach(tasks => {
      tasks.forEach(task => {
        totalProgress += task.latestProgress * task.fileSize;
        totalSize += task.fileSize;
      });
    });

    // Вычисление общего прогресса загрузки
    this.totalUploadProgress = totalSize > 0 ? (totalProgress / totalSize) : 0;
    this.totalUploadProgressNotifier.next(this.totalUploadProgress);
    console.log('** ', this.totalUploadProgress)

    this.updateStatusMessage();
  }

  private updateStatusMessage() {
    let newStatusMessage = '';
    newStatusMessage = `${this.filesUploadedCount} of ${this.files.length} files uploaded`;


    if(this.filesUploadedCount === this.files.length) {
      newStatusMessage = 'Success: All files have been uploaded!';
      this.filesUploadedCount = 0;
      this.files = [];
    }

    if(this.preProcessModelNumber > 0 && this.filesUploadedCount === this.files.length) {
      newStatusMessage = `Preprocessing ${this.preProcessModelNumber} models ...`;
    }

    this.statusMessageSource.next(newStatusMessage); // Update BehaviorSubject
    console.log('total upload progress: ', this.totalUploadProgress)
  }

  private fileUploadSuccess() {
    console.log('fileUploadSuccess');
    this.filesUploadedCount++;
    this.updateStatusMessage();
    // ... other logic for handling a successful upload ...
  }

  private resetTotalProgress() {
    this.uploadTasks = {}
    this.totalUploadProgress = 0;
    this.totalUploadProgressNotifier.next(this.totalUploadProgress);
  }

  // public handleFileInputWebpanel(event: any): NgxFileDropEntry[]  {
  //   console.log(event);
  //   const inputElement = event.target as HTMLInputElement;
  //   const files = event.target.files;
  //   let output = [];

  //   for (const file of files) {
  //     output.push(`${file.webkitRelativePath}`);
  //   }

  //   console.log(output);

  //   if (inputElement.files) {
  //     const files = inputElement.files;
  //     // Process or iterate over the files here
  //     for (let i = 0; i < files.length; i++) {
  //       const file = files[i];
  //       console.log(file.name); // Log the name of each file
  //     }
  //   }
  // }

// Assuming NgxFileDropEntry and other imports are correctly in place

// public handleFileInputWebpanel(event: any): NgxFileDropEntry[] {
//   const inputElement = event.target as HTMLInputElement;
//   let output: NgxFileDropEntry[] = [];

//   if (inputElement.files) {
//     const files = inputElement.files as any;

//     for (let i = 0; i < files.length; i++) {
//       const file = files[i];

//       // Instead of trying to mock FileSystemFileEntry, directly construct NgxFileDropEntry
//       const ngxFileDropEntry: NgxFileDropEntry = {
//         relativePath: file.webkitRelativePath || file.name,
//         // Directly use the file object; avoids the type error but diverges from FileSystemFileEntry structure
//         fileEntry: file as unknown as FileSystemFileEntry // This casting is a simplification
//       };

//       output.push(ngxFileDropEntry);
//     }
//   }

//   console.log(output);
//   return output;
// }


public handleFileInputWebpanel(event: Event, collectionId?: string): void {
  const inputElement = event.target as HTMLInputElement;
  const output: NgxFileDropEntry[] = [];

  if (inputElement.files) {
    const files = Array.from(inputElement.files);

    for (const file of files) {
      // Assuming FileSystemFileEntry's structure matches your implementation
      const mockFileEntry = {
        file: (callback: (fileData: File) => void) => callback(file),
        fullPath: (file as any).webkitRelativePath || file.name, // Cast to any to access webkitRelativePath
        name: file.name,
        isDirectory: false,
        isFile: true,
      };

      const ngxFileDropEntry: NgxFileDropEntry = {
        relativePath: (file as any).webkitRelativePath || file.name, // Cast to any to access webkitRelativePath
        fileEntry: mockFileEntry as any, // Using 'as any' to bypass strict type checking
      };

      output.push(ngxFileDropEntry);
    }
  }

  this.uploadFilesWithPreProcessing(output, collectionId);
}



}
