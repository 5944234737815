import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';

@Component({
  selector: 'app-webrenderlimit',
  templateUrl: './webrenderlimit.component.html',
  styleUrls: ['./webrenderlimit.component.scss']
})
export class WebrenderlimitComponent {
  @Output() webrenderlimitDialogueMainBtnClick = new EventEmitter<number>();

  renderAmountPrevious: number = NaN;
  renderAmountCurrent: number = NaN;
  minimumRenderLimit: number;

  constructor(
    public firebaseAuth: FirebaseAuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.minimumRenderLimit = data.requiredMinimum
    this.renderAmountPrevious = this.firebaseAuth.currentUser?.renderModelsPerCycle;
    this.renderAmountCurrent = this.firebaseAuth.currentUser?.renderLimit
  }

  onRenderLimitChange(renderAmount: number): void {
    this.renderAmountCurrent = renderAmount;
  }

  onMainBtnClick(): void {
    this.webrenderlimitDialogueMainBtnClick.emit(this.renderAmountCurrent);
    console.log('onMainBtnClick input value:');
  }
}
