<div class="top-part">
  <h2 mat-dialog-title class="web-account-title" (click)="betaSettings()" [attr.title]="!environment.production ? 'Click to navigate to Beta Settings' : 'Your account'" [ngClass]="{'clickable' : !environment.production}" >Your Account</h2>
  <div class="profile-wrapper">
    <div class="profile-region" >

      <ng-container #subscriptionRegion  >
        <div class="profile-section" fxLayout="column" >
          <div class="profile-section-bottom-part" fxFlex="1 1 100" fxLayout="column" fxLayoutAlign="center center">
            <div class="top-level-zone" fxFlex="1 1 100" fxLayout="row" fxLayoutAlign="space-between center">
              <div class="left-info">
                <h3>{{firebaseAuth.currentUser?.renderModelsPerCycle}}</h3>
                <p>uploads during current billing period</p>
              </div>
              <div class="right-price">
                <h4>$4.99</h4><p class="small">per upload</p>
              </div>
            </div>
            <button *ngIf="isSubscriber() && !firebaseAuth.currentUser.isAutoRenewDisabled" [disabled]="firebaseAuth.isSubscriptionRelatedBtnDisabled" class="close-acc-btn" (click)="cancelSub()">
              <span>Cancel Subscription</span>
            </button>
            <button *ngIf="!isSubscriber()" [disabled]="firebaseAuth.isSubscriptionRelatedBtnDisabled" class="close-acc-btn" (click)="subscribe()">
              <span>Subscribe</span>
            </button>
            <button *ngIf="isSubscriber() && firebaseAuth.currentUser.isAutoRenewDisabled" [disabled]="firebaseAuth.isSubscriptionRelatedBtnDisabled" class="close-acc-btn" (click)="renew()">
              <span>Renew: $9.99/month</span>
            </button>
            <p class="subscription-end-label" *ngIf="isSubscriptionEndLabelShown()">
              If you cancel now, you can still access your subscription until <br> {{ getFormattedEndDate() }}
            </p>
            <p class="subscription-end-label" *ngIf="firebaseAuth.currentUser?.subscriptionEndDateTime && firebaseAuth.currentUser?.isAutoRenewDisabled">
              <span class="red-label">You have cancelled your subscription.</span> <br>
              Your subscription ends on {{ getFormattedEndDate() }}
            </p>
          </div>
        </div>
    </ng-container>

    <div class="region-head" *ngIf="userDataService.isUserSubscribed" fxLayout="row" (click)="toggleRegion($event, PROFILE_REGION.RENDER_LIMIT)" fxLayoutAlign="space-between center" >
      <h2 class="region-title" (click)="toggleRegion($event, PROFILE_REGION.RENDER_LIMIT)">Billing Protection</h2>
      <svg *ngIf="!isRenderLimitRegionShown" class="arrow-right"  width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.RENDER_LIMIT)">
        <path d="M0.75 0.75L5.75 7L0.75 13.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)"/>
      </svg>
      <svg *ngIf="isRenderLimitRegionShown" class="arrow-down"  width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.RENDER_LIMIT)" >
        <path d="M13.25 0.75L7 5.75L0.75 0.75" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" />
      </svg>
    </div>

    <ng-container  #renderLimitRegion *ngIf="this.isRenderLimitRegionShown && userDataService.isUserSubscribed"  >
      <div class="profile-section" fxLayout="column" style="padding-bottom: 5px" [@slideToggle]="isRenderLimitRegionShown">
        <div class="profile-section-bottom-part" fxFlex="1 1 100" fxLayout="row" fxLayoutAlign="center center">
          <app-renderlimit-select style="width: 347px" (renderLimitChange)="onRenderLimitChanged($event)"></app-renderlimit-select>
        </div>
      </div>
    </ng-container>

      <div class="region-head" fxLayout="row" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" fxLayoutAlign="space-between center" >
        <h2 class="region-title" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)">Account Management</h2>
        <svg *ngIf="!isAccountRegionShown" class="arrow-right"  width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)">
          <path d="M0.75 0.75L5.75 7L0.75 13.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)"/>
        </svg>
        <svg *ngIf="isAccountRegionShown" class="arrow-down"  width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" >
          <path d="M13.25 0.75L7 5.75L0.75 0.75" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" (click)="toggleRegion($event, PROFILE_REGION.PERSONAL)" />
        </svg>
      </div>
      <ng-container #profileRegion *ngIf="this.isAccountRegionShown"  >
          <div class="profile-section" fxLayout="column" [@slideToggle]="isAccountRegionShown">
            <div class="profile-section-bottom-part" fxFlex="1 1 100" fxLayout="row" fxLayoutAlign="center center">
              <button class="close-acc-btn" (click)="deleteAccClick()">
                <span> Close my account and delete my data</span>
              </button>
            </div>
          </div>
      </ng-container>



      <!-- <div class="region-head" *ngIf="!environment.production" fxLayout="row" (click)="betaSettings()" fxLayoutAlign="space-between center" >
        <h2 class="region-title" (click)="betaSettings()">Beta Settings</h2>
      </div> -->
      <div class="region-head" fxLayout="row" (click)="signOut()" fxLayoutAlign="space-between center" >
        <h2 class="region-title" (click)="signOut()">Sign Out</h2>
      </div>
    </div>

  </div>
</div>

<div class="bottom-part">
  <p class="terms-text">
    <u><a href="/legal/terms-of-service">Terms of Service</a></u> and <u><a href="/legal/privacy-policy">Privacy Policy</a></u>
  </p>
  <mat-dialog-actions align="center">
    <button class="back-btn" mat-dialog-close  >
      <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span  class="web-btn-text">Back</span>
    </button>
    <a class="contact-us-btn" href="{{utilityService.generateHref()}}" (click)="utilityService.logContactUsClick()"><span>Contact Us</span> </a>

    <!-- THIS IS DONE FOR PROPER ALIGNMENT IN A FAST WAY -->
    <button class="back-btn" [hidden]="true"  style="visibility: hidden;">
      <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span  class="web-btn-text">Back</span>
    </button>
  </mat-dialog-actions>
</div>



