import { Injectable } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import { FOOTER_MODE } from 'src/app/models/menu';

@Injectable({
  providedIn: 'root'
})
export class MobileCommonService {
  private signOutSubject: Subject<void> = new Subject<void>();
  public triggerRenderLimitEvent: Subject<void> = new Subject<void>();
  public renderLimitIncreaseResultSubject: Subject<boolean> = new Subject<boolean>();
  public cachedFooterState: FOOTER_MODE;
  public cachedRenderLimitNumber: number;

  constructor() { }

  public triggerSignOut() {
    this.signOutSubject.next();
  }

  public getSignOutSubject(): Subject<void> {
    return this.signOutSubject;
  }

  public requestIncreasingRenderLimit(): Promise<boolean> {
    // Trigger the event to show the render limit warning
    this.triggerRenderLimitEvent.next();

    // Return a promise that resolves when the user responds
    return firstValueFrom(this.renderLimitIncreaseResultSubject.asObservable());
  }

  public setRenderLimitIncreaseResult(result: boolean) {
    this.renderLimitIncreaseResultSubject.next(result);
    // Optionally reset the subject for future use
    this.renderLimitIncreaseResultSubject = new Subject<boolean>();
  }

}


