<h2 mat-dialog-title class="web-dialog-title">Billing Protection</h2>

<mat-dialog-content>
  <p class="limit-title-msg">{{renderAmountPrevious}} models uploaded during current billing cycle</p>
  <app-renderlimit-select [inputMaxElementsInRow]="5" (renderLimitChange)="onRenderLimitChange($event)"></app-renderlimit-select>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button class="back-btn" mat-dialog-close  >
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span  class="web-btn-text">Back</span>
  </button>
  <button class="create-btn" [disabled]="renderAmountCurrent < minimumRenderLimit" (click)="onMainBtnClick()">
    <span>Increase</span>
  </button>

  <!-- THIS IS DONE FOR PROPER ALIGNMENT IN A FAST WAY -->
  <button class="back-btn" [hidden]="true"  style="visibility: hidden;">
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span  class="web-btn-text">Back</span>
  </button>
</mat-dialog-actions>
