export enum FOOTER_MODE {
  SERVICE = 'service',
  SEARCH = 'search-basic',

  WEB_HOME = 'web-home',
  WEB_SANDBOX = 'web-sandbox',

  LIBRARY = 'library',
  LIBRARY_COL_DETAILS = 'library-col-details',
  LIBRARY_LIKED_COLLECTIONS_DEATAIL = 'libarary-liked-col',
  LIBRAR_COL_DETAILS_PURCHASE = 'library-col-details-purchased',
  LIBRARY_SELECT_MODELS = 'library-select-models',
  LIBRARY_SELECT_MODELS_DISABLED = 'library-select-models-disabled',
  LIBRARY_SELECT_COLLECTION_TO_MOVE = 'library-select-collection-to-move',
  LIBRARY_SELECT_COLLECTION_TO_MOVE_DISABLED = 'library-select-collection-to-move-disabled',
  LIBRARY_SEE_ALL = 'library-see-all',
  LIBRARY_PUBLISH_REJECTED_REVIEW = 'library-publish-rejected-review',
  LIBRARY_DEFAULT_USER_COLLECTION = 'library-default-user-collection',
  RENAME_ITEM = 'rename-item',
  RESTORE_ITEMS = 'restore-items',
  ALL_PURCHASES = 'all-purchases',

  SUBSCRIPTION_SPLASH_SCREEN = 'sub-splash-screen',

  EDIT_COLLABORATORS = 'edit-collaborators',
  REMOVE_COLLABORATORS = 'remove-collaborators',
  REVIEW = 'review',

  RENDER_LIMIT_WARNING = 'render-limit-warning',

  NEW_COLLECTION = 'new-collection',
  EDIT_COLLECTION = 'edit-collection',
  ACTIVE_EDIT_COLLECTION = 'active-edit-collection',
  ADD_FROM_LIBRARY = 'add-from-library',
  // FILE_UPLOAD_DIALOGUE = 'file-upload-dialogue',

  SET_PRICE = 'set-price',
  PUBLISH_SELECTED_MODELS = 'publish-selected-models',

  DEFAULT = 'default',
  PORTFOLIO = 'portfolio',
  PROFILE = 'profile',
  COLLECTION_DETAILS = 'card-details',
  COLLECTION_DETAILS_FOR_SHARED = 'collection-details-limited',
  COLLECTION_DETAILS_TRANSITION_TO_HOME = 'collection-details-transition-to-home',
  CARD_DOWNLOAD_PURCHASED = 'card-download-purchased',
  CARD_BUY_SECTION = 'card-buy-section',
  SHOW_BUY_BTN = 'buy-btn',
  CARD_FULLSCREEN = 'card-fullscreen',
  CARD_FULLSCREEN_3D = 'card-fullscreen-3d',
}

export enum NAVBAR_BTN {
  BACK = 'back',
  BACK_PROFILE = 'back-profile',
  SAVE = 'save',
  HOME = 'home',
  SEARCH = 'search',
  ACCOUNT = 'account',
  CREATE_COLLECTION = 'create-collection',
  INVITE_COLLABORATOR = 'invite-collaborator',
  REMOVE_COLLABORATOR = 'remove-collaborator',
  ADD = 'add',
  NEXT = 'next',
  LIBRARY = 'library',
  PUBLISH = 'publish',
  SHARE = 'share',
  DOWNLOAD_SELECTED_MODELS = 'download-selected-models',
  ORGANIZE = 'organize',
  IN_REVIEW = 'in-review',
  RESTORE = 'restore',
  UPLOAD = 'upload',
  TO_TOP = 'to-top',
  BUY = 'buy',
  SELECT_ALL = 'select-all',
  LIBRARY_SEE_ALL = 'library-see-all',
  NOT_IMPL = 'not implemented',
  MORE_OPTIONS = 'more-options',
  INCREASE_RENDER_LIMIT = 'increase-render-limit',

  SUBSCRIBE = 'subscribe',
  FREE_TRIAL = 'free-trial'
}
