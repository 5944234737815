import {NgModule} from '@angular/core';
import { TermsOfServiceComponent } from './web/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './web/privacy-policy/privacy-policy.component';
import { WebLandingComponent } from './web/weblanding/weblanding.component';
import { MaterialModule } from './shared/material.module';
import { YoutubeDialogComponent } from './web/youtube-dialog/youtube-dialog.component';
import { SharedModule } from './shared/shared.module';
import { FooterComponent } from './web/footer/footer.component';
import { WebnavComponent } from './web/webnav/webnav.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { RouterModule } from '@angular/router';
import { FullsizeDragdropComponent } from './web/fullsize-dragdrop/fullsize-dragdrop.component';
import { WeblibraryComponent } from './web/weblibrary/weblibrary.component';
import { WebEditCollectionComponent } from './web/webedit-collection/webedit-collection.component';
import { WebPortfolioComponent } from './web/webportfolio/webportfolio.component';
import { WebuploadsandboxComponent } from './web/webuploadsandbox/webuploadsandbox.component';
import { WebpanelComponent } from './web/webpanel/webpanel.component';
import { WebpopupdialogComponent } from './web/webpopupdialog/webpopupdialog.component';
import { WebModelsCardComponent } from './web/web-models-card/web-models-card.component';
import { WebCollectionDetailsComponent } from './web/web-collection-details/web-collection-details.component';
import { WebaddfromComponent } from './web/webaddfrom/webaddfrom.component';
import { WebHomeComponent } from './web/webhome/webhome.component';
import { TweakerstyleComponent } from './web/tweakerstyle/tweakerstyle.component';
import { NgxStripeModule } from 'ngx-stripe';
import { WebstripepaymentComponent } from './web/webstripepayment/webstripepayment.component';
import { WebsubscriptionComponent } from './web/websubscription/websubscription.component';
import { environment } from 'src/environments/environment';
import { WebaccountComponent } from './web/webaccount/webaccount.component';
import { WebrenderlimitComponent } from './web/webrenderlimit/webrenderlimit.component';



@NgModule({
  declarations: [
    WebLandingComponent,
    WebHomeComponent,
    WebuploadsandboxComponent,
    WebEditCollectionComponent,
    WebPortfolioComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    YoutubeDialogComponent,
    FooterComponent,
    WebnavComponent,
    FullsizeDragdropComponent,
    WeblibraryComponent,
    WebpanelComponent,
    WebpopupdialogComponent,
    WebModelsCardComponent,
    WebCollectionDetailsComponent,
    WebaddfromComponent,
    TweakerstyleComponent,
    WebstripepaymentComponent,
    WebsubscriptionComponent,
    WebaccountComponent,
    WebrenderlimitComponent,
  ],
  imports: [
    MaterialModule,
    SharedModule,
    RouterModule,
    NgxFileDropModule,
    NgxStripeModule.forRoot(environment.stripeKey),

  ],
  exports: [
    NgxFileDropModule,

  ]
})
export class WebModule {}
